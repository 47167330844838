@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;500;700;900&display=swap');
@import url('/src/xeicon/xeicon.min.css');

/**************************************************************************************************************
	변수
**************************************************************************************************************/
:root { 
	--font-color1: #000;
	--font-color2: #555;
	--font-color3: #777;
	--font-color4: #999;
	--background-color1: #fefefe;
	--background-color2: #f9f9f9;
	--background-color3: #eee;
	--background-color4: #F0F6FF;
	--background-color5: #EEF5FF;
	--background-color6: #fde4e4;
	--line-color1: #d1d1d1;
	--line-color2: #d4d4d8;
	--line-color3: #4C90F6;
	--line-color4: #ddd;
	--line-color5: #001b64;
	--point-color1:#4C90F6;
	--point-color2:#ff5353;
	--point-color3:#545871;
	--point-color4:#D9E8FF;
	--state-color1:#e99c00;
	--state-color2:#de70a7;
	--state-color3:#a25fbc;
	--state-color4:#59647d;
}

/**************************************************************************************************************
	Common
**************************************************************************************************************/
html,body,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,address,big,cite,code,del,dfn,em,font,img,ins,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,ul,ol,li,dl,dt,dd,table,caption,tbody,tfoot,thead,tr,th,td,fieldset,fo7m,label,legend,input,button,textarea,select{margin:0;padding:0}
header,footer,section,article,aside,nav,hgroup,details,menu,figure,figcaption{display:block;margin:0;padding:0}
legend{display:block;overflow:hidden;position:absolute;width:1px;height:1px;font-size:1px;line-height:0;text-indent:-999em;white-space:nowrap; }
fieldset,img,table,caption,tbody,tfoot,thead,tr,th,td,button,hr{border:0 none}
img{vertical-align:middle; }
body li{vertical-align:top}
ul, li, ol{list-style:none; padding:0; margin:0;}
table{border-collapse:collapse}
caption{visibility:hidden;overflow:hidden;width:0;height:0;font-size:0;line-height:0;}
button{overflow:visible;border:0 none;cursor:pointer; border-radius:0; background: none; }
button::-moz-focus-inner,input[type="button"]::-moz-focus-inner,input[type="submit"]::-moz-focus-inner,input[type="reset"]::-moz-focus-inner {padding: 0;border: 0 none}
a { color: var(--font-color1);  }
address,
em { font-style:normal }
body,input,select,textarea,button,h1,h2,h3,h4,h5,h6 { font-family:'Roboto', 'Noto Sans KR', 'Noto Sans JP', 'MS PGothic', 'Meiryo', 'Osaka', sans-serif; font-size:14px; line-height: 1.55; color: var(--font-color1); text-decoration: none; font-weight:500; letter-spacing: -0.5px; word-break: keep-all;}
textarea,select { border:0;  border-radius:0; resize:none; box-shadow:none; -webkit-appearance:none; appearance:none; font-weight: normal;}
a:hover { text-decoration: none;}
body { -webkit-text-size-adjust: none; background:none; }
form { padding: 0; margin: 0; }
* {box-sizing: border-box; -webkit-box-sizing:border-box;}
::-ms-clear { display: none;}
select::-ms-expand { display:none; }
a,a:hover,a:active,a:visited,a:focus { text-decoration:none ; }
strong { font-weight: 600;}
b { font-weight: 700;}
* { box-sizing: border-box; }
html { font-size:10px; }
img { max-width:100%; }
button,input,select { vertical-align: middle;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.bg1 { background-color: var(--background-color1); }
.bg5 { background-color: var(--background-color5) !important}
.bg-red { background-color: var(--background-color6) !important;}

.pd10 { padding: 10px !important; }
.m0 { margin:0  !important }
.m10 { margin:10px !important }
.mb0 { margin-bottom:0  !important }
.mb5 { margin-bottom:5px !important }
.mb10 { margin-bottom:10px !important }
.mb15 { margin-bottom:15px !important }
.mb20 { margin-bottom:20px !important }
.mb25 { margin-bottom:25px !important }
.mb30 { margin-bottom:30px !important }
.mb35 { margin-bottom:35px !important }
.mb40 { margin-bottom:40px !important }
.mb50 { margin-bottom:50px !important }

.mr20 { margin-right:20px !important }
.mr60 { margin-right:60px !important }
.ml5 { margin-left:5px !important }
.ml10 { margin-left:10px !important }
.ml15 { margin-left:15px !important }
.ml40 { margin-left:40px !important }
.mt10 { margin-top:10px !important; }
.mt15 { margin-top:15px !important; }
.mt20 { margin-top:20px !important; }
.mt25 { margin-top:25px !important; }
.mt30 { margin-top:30px !important; }
.mt35 { margin-top:35px !important; }
.mt40 { margin-top:40px !important; }
.mt70 { margin-top:70px !important; }
.mt150 { margin-top:150px !important; }

.h170 { height: 170px !important; }

.text-center { text-align:center !important }
.text-left { text-align:left !important }
.text-right { text-align:right !important }
.text400 { font-weight: 400;}
.text500 { font-weight: 500;}
.text600 { font-weight: 600;}
.text700 { font-weight: 700;}

/* Text */
.f10 { font-size:10px !important; }
.f11 { font-size:11px !important; }
.f12 { font-size:12px !important; }
.f13 { font-size:13px !important; }
.f14 { font-size:14px !important; }
.f15 { font-size:15px !important; }
.f16 { font-size:16px !important; }
.f20 { font-size:20px !important; }
.f26 { font-size:26px !important; }
.f30 { font-size:30px !important; }
.point-color1 { color:var(--point-color1) !important; }
.font-color1 { color:var(--font-color1) !important; }
.font-color2 { color:var(--font-color2) !important; }
.font-color3 { color:var(--font-color3) !important; }
.font-color4 { color:var(--font-color4) !important; }
.line-color1 { border-color:var(--line-color1) !important; }
.line-color2 { border-color:var(--line-color2) !important; }
.line-color3 { border-color:var(--line-color3) !important; }
.txt-white { color:var(--point-color4) !important}
.txt-red { color:var(--point-color2) !important}
.txt-black { color:#111 !important}
.text500 { font-weight: 500 !important; }
.lh45 { line-height:45px; }
.nowrap { white-space: nowrap;}

.ck-editor__editable {
    min-height: 300px;
}

small.symbol { margin-left:3px; }

::-webkit-scrollbar { width:4px; height:6px; border-radius:4px;  }
::-webkit-scrollbar-thumb { width:4px; border-radius:4px; background: #bbb; }
::-webkit-scrollbar-track {  background:transparent; }
.hidden { position: absolute;left:0; opacity:0; width:0; height: 0; font-size:0; }

html { min-width:1500px; overflow:auto;}
/**************************************************************************************************************
	Bootstrap OVERRIDE
**************************************************************************************************************/
.fade { transition: opacity 0.15s linear;}
.modal {  
	--bs-modal-zindex: 1055;
	--bs-modal-width: 500px;
	--bs-modal-padding: 20px;
	--bs-modal-margin: 10px;
	--bs-modal-color: ;
	--bs-modal-bg: #000;
	--bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--bs-modal-header-padding-x: 1rem;
	--bs-modal-header-padding-y: 1rem;
	--bs-modal-header-padding: 1rem 1rem;
	--bs-modal-header-border-color: var(--bs-border-color);
	--bs-modal-header-border-width: var(--bs-border-width);
	--bs-modal-title-line-height: 1.5;
	--bs-modal-footer-gap: 0.5rem;
	--bs-modal-footer-bg: ;
	--bs-modal-footer-border-color: var(--bs-border-color);
	--bs-modal-footer-border-width: var(--bs-border-width);
	position: fixed;  top: 0;  left: 0;  z-index: var(--bs-modal-zindex);  display: none;  width: 100%;  height: 100%;  overflow-x: hidden;  overflow-y: auto;  outline: 0;
}
.modal-dialog { margin:0 auto; position: relative;  width: auto;   pointer-events: none; max-width:var(--bs-modal-width);}
.modal.fade .modal-dialog {  transform: translate(0, -50px);}
.modal.show .modal-dialog {  transform: none}
.modal.modal-static .modal-dialog {transform: scale(1.02);}
.modal-dialog-centered {  display: flex;  align-items: center;  min-height: calc(100% - var(--bs-modal-margin) * 2);}
.modal-content {  position: relative;  display: flex;  flex-direction: column;  width: 100%;   pointer-events: auto;  background-color: #fff;  background-clip: padding-box;  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);  border-radius: var(--bs-modal-border-radius);  outline: 0;}
.modal-backdrop {  --bs-backdrop-zindex: 1050;  --bs-backdrop-bg: #000;  --bs-backdrop-opacity: 0.5;  position: fixed;  top: 0;  left: 0;  z-index: var(--bs-backdrop-zindex);  width: 100vw;  height: 100vh;  background-color: var(--bs-backdrop-bg);}
.modal-backdrop.fade {  opacity: 0;}
.modal-backdrop.show {  opacity: var(--bs-backdrop-opacity);}
.modal-header {  display: flex;  flex-shrink: 0;  align-items: center;  justify-content: space-between;  padding:10px 15px;  border-bottom:1px solid var(--line-color1); }
.modal-header .btn-close {  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;}
.modal-header .btn-ico { font-size:25px; color:var(--font-color3); transition: all 0.5s ease;}
.modal-header .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}
.modal-title {  margin-bottom: 0;  line-height: var(--bs-modal-title-line-height);}
.modal-body { max-height: 80vh; overflow-y: auto; position: relative; flex: 1 1 auto; padding: var(--bs-modal-padding); }
.modal-footer {  display: flex; border-radius:0 0 10px 10px; overflow: hidden; }
.modal-footer button { flex:1; height:55px; font-size:16px; border-left:0 !important; border-bottom:0 !important; border-radius:0 !important }
.modal-footer button:last-child { border-right:0 !important }
.modal-content { border-radius:10px; }
.modal-header { padding: 20px; border-radius:10px 10px 0 0;}
.modal-title { font-size:20px; }
.modal .btn-close { width:24px; height:24px; background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") no-repeat 50% 50%;margin:0; }
.max-height-400 { max-height:400px; overflow: auto;}
.max-height-500 { max-height:500px; overflow: auto;}
.max-height-600 { max-height:600px; overflow: auto;}
.max-height-700 { max-height:700px; overflow: auto;}

/* Form */
::-webkit-input-placeholder { color:var(--font-color4);}
.inp::-webkit-input-placeholder { color: var(--font-color4)}
.textarea::-webkit-input-placeholder { color: var(--font-color4)}

.inp { height:45px; border-radius:5px; border: 1px solid var(--line-color1); background:var(--background-color1); padding-left: 15px; font-size:15px; -webkit-appearance:none; box-shadow:none; font-weight: 400; transition: all 0.5s ease; outline:none;; }
.inp:disabled { background-color: var(--background-color2); color: var(--font-color3);}
.inp.danger { border-color:var(--point-color2) !important; }
.inp:focus { border-color:var(--line-color3); background:#fff; }
.inp.block { width:100%; }
.inp.small { height: 35px; font-size:13px;  }
.inp.large { height: 56px; font-size:16px;  }
.inp.calendar { background-image: url(../img/common/ico_calendar.png);  background-position: right 10px center; background-repeat: no-repeat;}
.inp.calendar.small { background-size:auto 15px; }
.inp.text-center { padding:0; }
.inp.w300 { width:300px;}
.textarea { width:100%; height:170px; border-radius:5px; border: 1px solid var(--line-color1); padding: 16px; font-size:16px; -webkit-appearance:none; box-shadow:none; font-weight: 400; outline:none; transition: all 0.5s ease;;}
.textarea:disabled { background-color: var(--background-color2); color: var(--font-color3);}
.textarea.danger { border-color:var(--point-color2) !important; }
.textarea:focus { border-color:var(--line-color3) !important; background:#fff; }

.select { height:45px; border-radius:5px; border: 1px solid var(--line-color1); padding-left: 15px; padding-right:30px;  font-size:15px; -webkit-appearance:none; box-shadow:none; font-weight: 400; background: url(../img/common/bu_select.png) no-repeat right 50%; background-position:right 16px center; outline:none; transition: all 0.5s ease;}
.select.block { width:100%; }
.select.large { height: 56px; font-size:16px;  }
.select:disabled { background-color: var(--background-color2); color: var(--font-color3);}
.select.danger { border-color:var(--point-color2) !important; }
.select:focus { border-color:var(--line-color3); background-color:#fff; }
.select.w300 { width:300px; }

.checkbox { position: absolute; z-index:-100; opacity:0;width:0; height: 0; display: none;  }
.checkbox + div { display: inline-flex; align-items: center; }
.checkbox + div em { width:16px; height: 16px; border-radius:4px; background:#fff; position: relative; border: 1px solid var(--line-color1); transition: all 0.5s ease;}
.checkbox + div em:before { content: ''; position: absolute;left: 0; top:0; right:0; bottom:0; background: url(../img/common/checkbox.png) no-repeat 50% 50%; opacity:0; transition: all 0.5s ease; }
.checkbox + div p { font-size:14px; margin-left: 8px; font-weight: 400; }
.checkbox:checked + div em { background: #545871; border-color:#545871; }
.checkbox:checked + div em:before { opacity:1; }
.checkbox:disabled + div em { background: var(--background-color2)}
.checkbox:disabled:checked + div em { background: var(--background-color2); border-color:var(--line-color2); }
.checkbox.blue:checked + div em { background:var(--point-color1); border-color:var(--point-color1);}

.radio { position: absolute; z-index:-100; opacity:0;width:0; height: 0; display: none;  }
.radio + div { display: inline-flex; align-items: center; }
.radio + div em { width:16px; height: 16px; border-radius:100%; background:#fff; position: relative; border: 1px solid var(--line-color1); transition: all 0.5s ease;}
.radio + div em:before { content: ''; position: absolute;left: 3px; top:3px; right:3px; bottom:3px; border-radius:100%; background:var(--point-color1); opacity:0; transition: all 0.5s ease; }
.radio + div p { font-size:14px; margin-left: 8px; font-weight: 400; }
.radio:checked + div em { border-color:var(--point-color1) }
.radio:checked + div em:before { opacity:1; }
.radio:disabled + div em { background: var(--background-color2)}
.radio:disabled:checked + div em { background: var(--background-color2); border-color:var(--line-color2); }
.switch2 { position: absolute;left: 0; opacity:0; width:0; height: 0;}
.switch2 + div { display: flex; align-items: center; gap:8px; cursor: pointer;}
.switch2 + div span { font-size:14px; }
.switch2 + div p { display: inline-block; vertical-align: middle; width:42px; height: 24px;position: relative; background: #ccc; border-radius:24px;  transition: all 0.5s ease; }
.switch2 + div p em { position: absolute; right:2px; top:2px; width:20px; height: 20px; border-radius:100%; background: #fff; transition: all 0.5s ease; display:flex; justify-content: center; align-items: center; color:#999; box-shadow:2px 2px 4px rgba(0,0,0,0.2); }
.switch2 + div p em .xi-check { display:none;}
.switch2:checked + div p { background:var(--point-color1);}
.switch2:checked + div p em { right:20px; background:#fff; color:var(--point-color1) ; }
.switch2:checked + div p em .xi-check { display:inline;}
.switch2:checked + div p em .xi-close { display:none;}


.radio-box { display: inline-flex; padding:10px; border-radius:45px; padding:5px; border:1px solid var(--line-color1); }
.radio-box .radio-txt { position: absolute; z-index:-9999; opacity:0;}
.radio-box .radio-txt + p { cursor: pointer; transition: all 0.5s ease; height:30px; border-radius:30px; width:100px; line-height: 30px; text-align:center; font-size:15px; color:var(--font-color4);}
.radio-box .radio-txt:checked + p { background-color: var(--point-color1); color:#fff; font-weight:600; }


.switch { position: absolute;left: 0; opacity:0; width:0; height: 0;}
.switch + div { display: flex; align-items: center; gap:8px; cursor: pointer;}
.switch + div span { font-size:14px; }
.switch + div em { display: inline-block; vertical-align: middle; width:38px; height: 24px;position: relative; background: var(--font-color4); border-radius:24px;  transition: all 0.5s ease; }
.switch + div em:before { content: ''; position: absolute; right:16px; top:2px; width:20px; height: 20px; border-radius:100%; background: #fff; transition: all 0.5s ease;}
.switch:checked + div em { background: var(--brand-color1);}
.switch:checked + div em:before{ right:2px; }

.btn-arr { display: inline-block; vertical-align: middle; color:var(--font-color3); }
.btn-arr:after { content:''; display:inline-block; vertical-align: middle; width:7px; height:7px; border-left:1px solid var(--point-color3);border-bottom:1px solid var(--point-color3); transform: rotate(-135deg); margin:-3px 0 0 4px;}
.btn-refresh2 { display: inline-block; vertical-align: middle; color:var(--font-color3); }
.btn-refresh2 i { font-size:17px;display: inline-block; vertical-align: middle; margin:-2px 0 0 3px; }

.btn-link { display: inline-block; vertical-align: middle; padding-bottom: 3px; border-bottom: 1px solid var(--line-color1); color:var(--font-color3); position: relative; transition: all 0.5s ease;}
.btn-link:before { content: ''; position: absolute; left: 0; bottom:-1px; width:0; height:1px; background-color: #000; transition: all 0.5s ease;}
.btn-link:hover { color:#111; }
.btn-link:hover:before { width:100%; }
.btn-link.blue { color:var(--point-color1); }
.btn-link.blue:before { border-color:var(--point-color1) }
.btn-link + .btn-link { margin-left: 15px; ;}
.btn { height: 45px; border-radius:5px; padding:0 25px; font-size:15px;   transition: all 0.5s ease; white-space:nowrap}
.btn:hover { box-shadow:4px 4px 12px rgba(0,0,0,0.2); }

.btn.block { width:100%; padding:0;}
.btn:disabled { cursor: no-drop}
.btn.btn-lg { height: 56px; font-size:18px; padding:0 25px;  }
.btn.btn-m { height: 40px; padding:0 20px;  }
.btn.btn-s { height: 32px; padding:0 15px; font-size:12px;  }
.btn.btn-fill-blue { background: var(--point-color1); color: #fff; }
.btn.btn-fill-blue:hover {  }
.btn.btn-fill-blue:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-fill-blue2 { background: var(--point-color3); color: #fff; }
.btn.btn-fill-blue2:hover {  }
.btn.btn-fill-blue2:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-fill-blue3 { background: var(--point-color4); color: var(--point-color1); border:1px solid var(--point-color4); }
.btn.btn-fill-blue3:hover { border-color:var(--point-color1);  }
.btn.btn-fill-blue3:disabled { color: var(--font-color4); background:var(--background-color2); }
.btn.btn-fill-black { background:#111; color:#fff; }
.btn.btn-fill-black:hover {  }
.btn.btn-fill-black:disabled { background:var(--background-color2); color: var(--font-color4);  }
.btn.btn-fill-gray { background:var(--background-color2); color:var(--font-color2); border:1px solid var(--line-color1) }
.btn.btn-fill-gray:hover { border-color:var(--line-color3); color:var(--font-color1); }
.btn.btn-fill-gray:disabled { background:var(--background-color2); color: var(--font-color4);  }
.btn.btn-fill-gray2 { background:#f2f2f2; color:#5d6962; border:1px solid var(--background-color2) }
.btn.btn-fill-gray2:hover {  }
.btn.btn-fill-gray2:disabled { background:var(--background-color2); color: var(--font-color4);  }
.btn.btn-fill-gray3 { background:var(--background-color2); color:var(--font-color2); border:1px solid var(--line-color1); border-radius: 10px;}
.btn.btn-fill-gray3:active { background:var(--background-color4); color:var(--font-color2); border:1px solid var(--line-color1) }

.btn.btn-line-blue { border:1px solid var(--point-color1); color:var(--point-color1); }
.btn.btn-line-blue:hover {  }
.btn.btn-line-blue:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;  }
.btn.btn-line-blue2 { border:1px solid var(--point-color3); color:var(--point-color3) }
.btn.btn-line-blue2:hover {  }
.btn.btn-line-blue2:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;  }
.btn.btn-line-black { border:1px solid #000;  }
.btn.btn-line-black:hover { background:#000; color:#fff; }
.btn.btn-line-black:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;}

.btn.btn-line-gray { border:1px solid var(--line-color1);  color:var(--font-color3);  }
.btn.btn-line-gray:hover { background:#eee; }


.btn-round { border-radius:56px; }
.btn.btn-icon { border:1px solid var(--line-color1);  }
.btn.btn-icon:hover { background:#eee }
.btn.btn-icon:disabled { color: var(--font-color4); background:var(--background-color2); border: 0;}
.btn i { vertical-align: middle; margin:-3px 4px 0;}
.btn i:first-child { margin-left:0;}
.btn i:last-child { margin-right:0;}
.btn img { margin:-2px 2px 0;}
.btn-kind-add { width:250px; height:80px; background-color: var(--background-color3); position: relative; border-radius:10px;}
.btn-kind-add .plus:before { content:''; position: absolute; left: 50%; top:50%; width:30px; height:4px; background-color: var(--point-color3); margin:-2px 0 0 -15px; }
.btn-kind-add .plus:after { content:''; position: absolute; left: 50%; top:50%; width:30px; height:4px; background-color: var(--point-color3); margin:-2px 0 0 -15px; transform: rotate(90deg);}

.checkbox-text1 { position:absolute; opacity:0; z-index:-1;}
.checkbox-text1 + div { display:inline-block; vertical-align:middle; border:1px solid #000; line-height:38px; color:#000; height: 40px; border-radius:5px; padding:0 25px; font-size:15px;   transition: all 0.5s ease; white-space:nowrap; cursor: pointer; }
.checkbox-text1:checked + div { background-color:#000; color:#fff; border-color:#000; }

.checkbox-text2 { position:absolute; opacity:0; z-index:-1;}
.checkbox-text2 + div { display:inline-block; vertical-align:middle; border:1px solid #000; line-height:38px; color:#000; height: 40px; border-radius:5px; padding:0 25px; font-size:15px;   transition: all 0.5s ease; white-space:nowrap; cursor: pointer; border-radius:40px;}
.checkbox-text2 + div i { display:none; }
.checkbox-text2:checked + div { background-color:var(--background-color4); color:var(--point-color1); border-color:var(--point-color1); }
.checkbox-text2:checked + div i { display:inline; }


.ico-excel { display: inline-block; vertical-align: middle; width:21px; height:21px; background: url(../img/common/ico_excel.png) no-repeat  0 0;}
.ico-upload { display: inline-block; vertical-align: middle; width:8px; height:10px; background: url(../img/admin/ico_upload.png) no-repeat  0 0;}
.ico-download { display: inline-block; vertical-align: middle; width:8px; height:10px; background: url(../img/admin/ico_download.png) no-repeat  0 0;}
.tit-excel { font-size:13px;  }
.tit-excel i { margin:-3px 5px 0 0;}
.form-tit { font-size:15px; margin-bottom:5px;}
.form-div { display:flex; gap:10px; margin-bottom:25px;}
.form-div div{ height:45px; border-radius:5px; border:1px solid; border-color:#ddd; padding:10px; width:100%;}
.inp-box { margin-bottom:25px;  position: relative;}
.inp-box .in { padding-right: 50px;}
.inp-btn-box { display:flex; gap:10px; }
.inp-btn-box .btn { white-space: nowrap;}
.inp-btn-box .time {  position:absolute; right:110px; top:0; bottom:0; display: flex; align-items: center; color:var(--font-color3); }
.inp-btn-box .time + .inp { padding-right:100px; }
.inp-btn-box .nowrap { width:190px; }
.inp-box .btn-search { position:absolute; right:0; top:0; height:100%; width:45px; border:0; outline:none; background: url(../img/common/ico_search.png) no-repeat 50% 50%;}
.inp-box .btn-search + .inp { padding-right:50px; }
.inp-box .inside { position:absolute; right:calc(40% + 15px); top:0; line-height:45px; font-size:15px; color:var(--font-color4);}
.inp-box .right { position:absolute; right:15px; top:0; line-height:45px; font-size:15px; color:var(--font-color4);}
.inp-box .right + .inp { text-align:right; padding-right:60px; }
.inp-box .inp-icon { position:absolute; right:20px; top:50%; transform: translateY(-50%); font-size:22px; color:var(--font-color4); cursor: pointer;}
.inp-box .inp-icon .xi-eye-off { display:none; }
.inp-box .inp-icon.active .xi-eye { display:none; }
.inp-box .inp-icon.active .xi-eye-off { display:inline; }

.btn-common-box { display:flex; gap:10px; justify-content: space-between; margin-top:25px;}
.btn-common-box .inner1100,
.btn-common-box .inner1300 { display:flex; gap:10px; justify-content: space-between; }
.btn-common-box .btn { flex:1;}
.btn-common-box.auto .btn { flex: 0 0 auto; }
.btn-common-box.fixed { position:fixed; left: 90px; right:0; bottom:0; padding:20px; background:#fff; border-top:1px solid var(--line-color1); display:block; z-index:10;}
.form-msg { color:var(--font-color3); }
.inp-box  + .form-msg { margin-top:-15px; }
.form-msg + .form-tit { margin-top:25px;}
.form-msg.impact { border: 1px solid #ddd; border-radius:8px; background-color: #ddd; height: 60px; text-align: center; font-size: 16px; padding: 5px; display: flex; flex-direction: column; justify-content: space-evenly; }
.form-validation { display:flex; gap:15px; align-items: center; margin:10px 0 25px;}
.form-validation span { }
.form-validation .bar { flex:1; height:8px; position:relative; border-radius:8px; overflow:hidden; background-color: var(--background-color2);}
.form-validation .bar:before { content: ''; position: absolute; left: 0; top:0; height:100%; border-radius:8px; background-color: #000;}
.form-validation .bar.step1:before { width:25%; background-color: #FF5353;}
.form-validation .bar.step2:before { width:50%; background-color: #FF5353;}
.form-validation .bar.step3:before { width:75%; background-color: #FB8110;}
.form-validation .bar.step4:before { width:100%; background-color: #4ECB71; }
.form-validation .bar.step1 + strong { color:#FF5353; }
.form-validation .bar.step2 + strong { color:#FF5353; }
.form-validation .bar.step3 + strong { color:#FB8110; }
.form-validation .bar.step4 + strong { color:#4ECB71; }
.form-validation strong { font-weight:700; }

.error-tip { background-color: #fce4e4;border: 1px solid #fcc2c3;border-radius: 7px;-moz-border-radius: 7px;-webkit-border-radius: 7px;color: #921837;float: left;font-weight: bold;line-height: 24px;position: relative;padding: 7px 11px 4px;margin-top: 7px;}
.error-tip:before { content: '';border: 7px solid transparent;position: absolute;top: 11px; border-bottom: 7px solid #fcc2c3;top: -14px; }

/* Element  */
.badge { display: inline-block; height: 32px; line-height: 30px;  padding:0 8px; border-radius:5px;  border:1px solid transparent; }
.badge.blue { border-color:#2F61D5; color: #2F61D5;  }
.badge.green { border-color:#059C44; color:#059C44; }
.badge.bg-purple { border-color:#8D53E6; color:#fff; background: #8D53E6; }

.label {  display: inline-block; height: 20px !important; line-height: 18px !important;  padding:0 8px; min-width:auto !important; border-radius:3px !important;  border:1px solid #4C90F6; font-size:11px !important; color:#4C90F6; background: rgba(76, 144, 246, 0.1); }
.label.label1 { color:#4C90F6; border-color:#4C90F6; background: rgba(76, 144, 246, 0.1);}
.label.label2 { color:#4ECB71; border-color:#4ECB71; background: rgba(78, 203, 113, 0.1);}
.label.label3 { color:#FF6564; border-color:#F50100; background: rgba(245, 1, 0, 0.1);}
.label.label4 { color:#FFAF66; border-color:#FBC710; background: rgba(251, 199, 16, 0.1);}

.label2 {  display: inline-block; height: 23px; line-height:23px; min-width:70px;  padding:0 8px; border-radius:22px;  font-size:13px; color:#fff; background-color: #afafaf;  cursor: pointer;}
.label2.bg-blue { background-color: var(--point-color1);}

/* Icon */
.ico-bookmark { display: inline-block; vertical-align: middle; width:8px; height:10px; background: url(../img/common/ico_bookmark_off.png) no-repeat 0 0;}
.ico-pin { display: inline-block; vertical-align: middle; width:10px; height:11px; background: url(../img/common/ico_pin_off.png) no-repeat 0 0;}

/* Common CSS */
.flex { display: flex; }
.flex-wrap{ flex-wrap: wrap;}
.flex-grow-0 { flex-grow: 0 !important;}
.flex .flex1 { flex: 1; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-center { justify-content: center; }
.align-items-center { align-items: center; }
.gap5 { gap:5px; }
.gap10 { gap:10px; }
.gap12 { gap:12px; }
.gap15 { gap:15px; }
.gap20 { gap:20px; }
.gap30 { gap:30px; }
.gap40 { gap:40px; }
.gap50 { gap:50px; }
hr { display:block; width:100%; height:1px; background-color: var(--line-color1); margin:30px 0; padding:0; }
.position-absolute { position:absolute; }
.right10 { right:10px}
.top10 { top:10px }
.inner400 { max-width:400px; margin:0 auto;}
.inner500 { max-width:500px; margin:0 auto;}
.inner600 { max-width:600px; margin:0 auto;}
.inner700 { max-width:700px; margin:0 auto;}
.inner800 { max-width:800px; margin:0 auto;}
.inner900 { max-width:900px; margin:0 auto;}
.inner1000 { max-width:1000px; margin:0 auto;}
.inner1100 { max-width:1100px; margin:0 auto;}
.inner1200 { max-width:1200px; margin:0 auto;}
.inner1300 { max-width:1300px; margin:0 auto;}
.inner1400 { max-width:1400px; margin:0 auto;}
.row { display:flex; gap:20px; margin-bottom: 25px;}
.row .col { flex:1; }
.row.auto-size .col { flex:auto; }

.grid { display: grid; margin-bottom: 20px;}
.grid-plan { grid-template: repeat(auto-fill, minmax(200px, 1fr)) / repeat(2, minmax(200px, 1fr)); gap: 20px;}

/* Title */
.h2 { font-size:32px; font-weight:700; color:var(--font-color1); margin-bottom:25px;}
.h3 { font-size:18px; font-weight:700; color:var(--font-color1); margin-bottom:15px;}
.h3 small { display:block; }
.h4 { font-size:16px; font-weight:700; color:var(--font-color2); margin-bottom:12px;}
.h3-desc { font-size:16px; font-weight:700; margin-bottom:12px; }
.h3-desc small { display:block; font-size:13px; color:var(--font-color3); }

/* Tabs */
.tabs { display:flex; margin-bottom:25px; }
.tabs button { flex:1; height:45px; border:1px solid var(--line-color1); font-size:16px; color: var(--font-color3); transition: all 0.5s ease; }
.tabs button + button { border-left: 0;}
.tabs button:first-child { border-radius:5px 0 0 5px; }
.tabs button:last-child { border-radius:0 5px 5px 0; }
.tabs button.active { background-color: var(--point-color1); color:#fff; border-color:var(--point-color1); }
.tabs button:not(.active):hover { color:#000; background-color: var(--background-color3);}

.tabs2 { display:flex; margin:-20px -20px 40px -20px; border-bottom:1px solid var(--line-color1);  }
.tabs2 button { flex:1; height:55px;  font-size:17px; color: var(--font-color4); position: relative;}
.tabs2 button.active { color:#111; } 
.tabs2 button:before { content:''; position: absolute; left: 50%; bottom:-1px; width:0;  height:4px; background-color: var(--point-color1); transition: all 0.5s ease; opacity:0; }
.tabs2 button.active:before { width:100%; left:0; opacity:1;  } 
.tabs2 button.end { background:var(--background-color1); }
.tabs2 button.end:after { content:''; display: inline-block; vertical-align: middle; width:20px; height:20px; background:url(../img/common/ico_check_on.png) no-repeat 0 0; margin:-3px 0 0 10px;}

.tabs3 { margin-left:20px; margin-bottom:-1px; position: relative; z-index:2; overflow: hidden; }
.tabs3 button { float:left; padding:0 25px; height:40px; font-size:14px; color:var(--font-color4); border-radius:5px 5px 0 0; background-color: var(--background-color5); border:1px solid var(--line-color4); border-bottom:0;  margin-right:-1px;}
.tabs3 button.active { background-color: #fff; position:relative; box-shadow:5px 0 5px rgba(0,0,0,0.05);} 

.tabs4 { display:flex;  }
.tabs4 button { height:49px; padding:0 25px; font-size:16px; color:var(--font-color4);position:relative; transition: all 0.5s ease;;}
.tabs4 button:before { content:''; position:absolute; left:0; right:0; bottom:-1px; height:2px; background:#000; transition: all 0.5s ease; opacity:0;}
.tabs4 button.active:before { opacity:1; }
.tabs4 button.active,
.tabs4 button:hover { color:#000;}

.choice-wrap .left { width: 175px; }
.menu-dep2 { margin-bottom: 90px; width: 650px; }
.menu-dep2 > ul { display: inline-flex; position: relative; gap:5px; }
.menu-dep2 li { }
.menu-dep2 .btn-dep1 { padding:0 20px; height:45px; font-size:16px; border-radius:5px;  transition: all 0.5s ease; }
.menu-dep2 ul ul { display:none; position: absolute; left: 0; top:45px; padding-top:10px; width: 650px;}
.menu-dep2 ul ul li { display: inline-block; margin-right:4px; margin-bottom: 5px;}
.menu-dep2 .btn-dep2 { padding:0 15px; line-height: 40px; height:42px; border-radius:5px; border:1px solid var(--line-color1); white-space:nowrap; color:var(--font-color3); transition: all 0.5s ease; }
.menu-dep2 .btn-dep3 { padding:0 15px; line-height: 40px; height:42px; border-radius:5px; border:1px solid var(--line-color1); white-space:nowrap; color:var(--font-color3); transition: all 0.5s ease; }
.menu-dep2 .btn-dep2:hover { background:#eee; }

.menu-dep2 > ul > li .btn-dep1.c0 { border:2px solid #c8e5a1; }
.menu-dep2 > ul > li .btn-dep1.c1 { border:2px solid #f9c5f2; }
.menu-dep2 > ul > li .btn-dep1.c2 { border:2px solid #babbe6; }
.menu-dep2 > ul > li .btn-dep1.c3 { border:2px solid #89B5F9; }
.menu-dep2 > ul > li .btn-dep1.c4 { border:2px solid #f6c39b; }

.menu-dep2 > ul > li.active .btn-dep1 { font-weight: 700; }
.menu-dep2 > ul > li.active .btn-dep1.c0 { background-color: #c8e5a1 }
.menu-dep2 > ul > li.active .btn-dep1.c1 { background-color: #f9c5f2 }
.menu-dep2 > ul > li.active .btn-dep1.c2 { background-color: #babbe6 }
.menu-dep2 > ul > li.active .btn-dep1.c3 { background-color: #89B5F9 }
.menu-dep2 > ul > li.active .btn-dep1.c4 { background-color: #f6c39b }
/* .menu-dep2 > ul > li.active .btn-dep1 { background-color: var(--background-color3); font-weight: 700;  } */
/* 수정중 */
.menu-dep2 > ul > li.active ul { display: block; margin-right: 10px; }
.menu-dep2 ul ul li.active .btn-dep2 {font-weight: 700; color:#fff;  background:var(--point-color1); border-color:var(--point-color1); }
.menu-dep2 ul ul ul li.active .btn-dep3 {font-weight: 700; color:#fff;  background:var(--point-color1); border-color:var(--point-color1);}

.implant-menu { width: 650px; padding: 10px; border: 0px dashed #d1d1d1; border-radius: 5px; }
.implant-menu > ul { display: flex; flex-wrap: wrap; position: relative; gap:5px; }
.implant-menu > ul > li { }
.implant-menu > ul > li .btn-dep1 { border:1px solid var(--line-color1); white-space:nowrap; color:var(--font-color3); padding:0 15px; height:45px; font-size:14px; border-radius:5px;  background-color: var(--background-color1); transition: all 0.5s ease;}
.implant-menu ul li.active .btn-dep1 {font-weight: 700; color:#fff;  background:var(--point-color1); border-color:var(--point-color1);}

.button {display: block;}
.button .hide{ display : none; transition: all 0.5s ease;}
.button .button-left { background-image: url(../img/common/ico_btn_left2_off.png); width: 40px; height: 40px; background-size: contain;}
.button .button-left:hover { background-image: url(../img/common/ico_btn_left2_on.png); width: 40px; height: 40px; background-size: contain;}
.button .button-right { background-image: url(../img/common/ico_btn_right2_off.png); width: 40px; height: 40px; background-size: contain;}
.button .button-right:hover { background-image: url(../img/common/ico_btn_right2_on.png); width: 40px; height: 40px; background-size: contain;}

.arrow {position: absolute; width: 50px; height: 100px; }
.arrow-left {background-image: url(../img/common/ico_btn_left1_off.png); background-repeat: no-repeat; background-size: contain; }
.arrow-left:hover {background-image: url(../img/common/ico_btn_left1_on.png); background-repeat: no-repeat; background-size: contain;}
.arrow-right {background-image: url(../img/common/ico_btn_right1_off.png); background-repeat: no-repeat; background-size: contain;}
.arrow-right:hover {background-image: url(../img/common/ico_btn_right1_on.png); background-repeat: no-repeat; background-size: contain;}


.delivery {position: absolute; z-index: 100; top: 50px; padding: 10px; min-width: 200px; background-color: #fefefe; box-shadow: 10px 10px 20px rgba(0,0,0,0,15);}

/**************************************************************************************************************
	페이지
**************************************************************************************************************/
body,html { min-height:100vh; }
#container { min-height:calc(100vh - 90px - 176px); }
#container.fixed-type { min-height:auto; padding-bottom: 100px;;}
.login-wrap { display:flex; height:100vh; align-items:center; justify-content: space-between; padding:0 3vw; }
.login-wrap .login-banner { flex:1; display:flex;  flex-direction: column; justify-content:center }
.login-wrap .login-banner img { width:342px; } 
.login-wrap .login-banner h2 { font-size:40px; font-weight:700; margin:25px 0; }
.login-wrap .login-banner p { font-size:16px; color:var(--font-color3); line-height:1.5; }
.login-wrap .login-box { flex:0 0 520px; padding:120px 70px; display:flex; justify-content: center; align-items: center; flex-direction: column; box-shadow: 0px 1px 40px #D9E8FF; }
.login-wrap .login-box > div { width:100%; }
.login-wrap .login-box h1 { font-size:34px; margin-bottom:45px; }
.login-wrap .login-box h1 strong { font-size:34px; display:block; }
.login-wrap .login-box .link-wrap { display: flex; justify-content: space-between; }
.login-wrap .login-box .find a { text-decoration: underline; color:var(--font-color3); }
.login-wrap .login-box .find a:hover { color:var(--font-color4); }
.login-wrap .login-box .signup a { color:var(--font-color1); }
.login-wrap .login-box .signup a:hover { color:var(--font-color2); }

.opt-in-wrap { display: flex; flex-direction: column; }

.join-type-wrap { height: 100vh; display:flex; align-items: center; flex-direction: column; justify-content: center;}
.join-type-wrap h2 { text-align:center; }
.join-type { display:flex; gap:20px; justify-content: space-between; margin:0 auto;}
.join-type .item { width:250px; border-radius:10px; border:1px solid var(--point-color1);  overflow:hidden;  transition: all 0.5s ease;}
.join-type .item h3 { text-align:center; height:50px; line-height:1.3; display:flex; justify-content: center; align-items: center; font-size:20px; background-color: var(--point-color1); color:#fff; }
.join-type .item .info { padding: 30px 20px; font-size: 14px; overflow-wrap: break-word }
.join-type .item .info h4 { font-size:18px; margin-bottom:8px; font-weight:700; }
.join-type .item .info .t1 + .t1 { margin-top:15px; }
.join-type .item .info .t2 {  margin:10px 0; color: var(--font-color3);}
.join-type .item:hover { box-shadow:10px 10px 20px rgba(0, 0, 0, 0.253);  background: var(--background-color5);}

.join-wrap { padding:50px 0; }
.form-inner { width:400px; margin:0 auto; }

.result-list ul {}
.result-list ul li { margin-bottom: 10px; }
.result-list ul li .btn-result { width:100%; text-align:left; padding:10px 10px 10px 130px; position: relative; }
.result-list ul li .btn-result:hover:before { border:1px solid var(--point-color1); box-shadow:10px 10px 15px rgba(0,0,0,0.1); }
.result-list ul li .btn-result:before { content: ''; position: absolute; left: 0; top:0; right:0; bottom:0; border-radius:10px;  border:1px solid var(--line-color1); transition: all 0.5s ease;}
.result-list ul li .btn-result .name { position:absolute; left: 10px; top:10px; bottom:10px; width:110px; word-break: break-all;; display:flex; align-items: center; justify-content: center; font-size:18px; font-weight:600; }
.result-list ul li .btn-result dl dt { font-size:13px; color:var(--font-color3); margin-bottom:3px; }
.result-list ul li .btn-result dl dd { font-size:13px; }
.result-list ul li .btn-result dl + dl { margin-top:5px; }

.plan-item table { width:100%; border-collapse: collapse; }
.plan-item table tbody th { padding:10px 15px; border:1px solid var(--line-color1); background-color: var(--background-color2); text-align: left;}
.plan-item table tbody td { padding:10px 15px; border:1px solid var(--line-color1); color:var(--font-color3)}
.plan-empty {border: 1px solid #ddd; border-radius: 10px; background-color: #fff; padding: 20px; text-align: center; flex: 1 1;}
.plan-empty > span { font-size: 18px;}
.plan-item .btn-ico { font-size:14px; color:var(--font-color3); transition: all 0.5s ease;}
.plan-item .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}

.table1 { margin-bottom:20px;}
.table1 table { width:100%; border-collapse: collapse; }
.table1 table thead th { padding:10px 15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); background-color: var(--background-color2); }
.table1 table tbody td { padding:10px 15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.table1 table tbody tr {  transition: all 0.5s ease;}
.table1 table tbody tr:nth-child(odd) { background-color: #F9FAFD;}
.table1.hover-type table tbody tr { cursor: pointer;}
.table1.hover-type table tbody tr:hover,
.table1 table tbody tr.active { background-color: var(--background-color4);}
.table1.hover-type table tbody tr.child-row:hover { background-color: #F9FAFD; cursor:pointer;}
.table1.hover-type table tbody tr:hover td,
.table1 table tbody tr.active td { color:#111;}
.table1 table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table1 tr.disabled { opacity:0.5; }

.table2 { margin-bottom:20px;}
.table2.cursor table tbody td { cursor: pointer; }
.table2 table { width:100%; border-collapse: collapse; } 
.table2 table thead th { padding:15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); background-color: var(--background-color2); text-align: center; }
.table2 table tbody td { padding:15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.table2 table tbody tr {  transition: all 0.5s ease; }
.table2 table tbody td .empty { text-indent: 20%;}
.table2 table tbody tr:hover,
.table2 table tbody tr.active  { background-color: var(--background-color4);}
.inq-price { display:inline-flex; gap:12px;}
.inq-price small { font-size:12px;  color:var(--font-color3)}
.inq-price strong { color:#111; font-weight:700; text-align: right; width:110px;} 
.inq-price input { width:80%; text-align:right; padding-right:5px; }
.inq-stat{ display:inline-flex; align-items: center; justify-content: space-between; width:70px; }
.inq-stat span { width:50px;text-align: center; color:var(--point-color3); }
.inq-stat span em { color:#B3B3B3; }

.table1 .subj { text-align:left; font-weight:700; color:#111; }

.privacy { font-size:13px; line-height: 1.4; margin-bottom:10px; }

/**************************************************************************************************************
	레이아웃 - 유저
**************************************************************************************************************/
#wrap-user {  padding:70px 20px 20px 110px; min-height:100vh;}
.header-user { position:fixed; left:90px; top:0; right:0; height:50px; width:auto; background-color: #fff; border-bottom:1px solid var(--line-color1); z-index:100; box-shadow:0 0 10px rgba(0,0,0,0.1);}
.header-user h2 { line-height:49px; font-size:16px; font-weight:700; padding-left:15px;}
.header-user .top { position:absolute; right:20px; top:0; height: 50px; display: flex; align-items: center; gap:15px;}
.header-user .top .alert { position:relative; }
.header-user .top .alert .btn-alert { background: url(../img/common/ico_top_alert_off.png) no-repeat 50% 50%; width:20px; height:20px; transition: all 0.5s ease; }
.header-user .top .alert .btn-alert:hover { background-image: url(../img/common/ico_top_alert_on.png);}
.header-user .top .alert-box {  position: absolute; right: -30px; width:350px; max-height: 700px; overflow-y: auto; top:30px;  border-radius:10px; background-color:#f9f9f9; z-index:10; box-shadow:5px 5px 20px rgba(0,0,0,0.1); padding:15px; border:1px solid #ddd; }
.header-user .top .alert-box .alert-head { display:flex; justify-content: space-between; margin-bottom:12px;}
.header-user .top .alert-box .alert-head strong { font-size:15px; }
.header-user .top .alert-box .alert-head span { font-size:13px; color:var(--font-color4); }
/* .header-user .top .alert-box:before{ content:''; position:absolute; right:40px; top:-15px; width: 0;	height: 0;	border-style: solid;	border-width: 0 10px 15px 10px;	border-color: transparent transparent #fff transparent; box-shadow:5px 5px 5px rgba(0,0,0,0.1);} */
.header-user .top .alert-box ul li + li { margin-top:10px;}
/* .header-user .top .alert-box ul li a { display:block; padding:12px 10px; border-radius:8px;  position:relative; background-color: #fff; box-shadow:4px 4px 8px rgba(0,0,0,0.1); }
.header-user .top .alert-box ul li a p { font-size:14px; line-height:1.5;  }
.header-user .top .alert-box ul li a span { font-size:12px; color:var(--font-color3); }
.header-user .top .alert-box ul li a strong { position: absolute; left: 15px; top:8px; }
.header-user .top .alert-box ul li a.type2 { background: url(../img/common/ico_tip_arr.png) no-repeat right 50%; background-position: right 15px center;}
.header-user .top .alert-box ul li a.type2 strong  {  color:var(--point-color2);} */

.header-user .top .alert-box ul li div { display:block; padding:12px 10px; border-radius:8px; width: 100%; text-align: left; position:relative; background-color: #fff; box-shadow:4px 4px 8px rgba(0,0,0,0.1); }
.header-user .top .alert-box ul li div:hover { background-color: #E6F0FF;}
.header-user .top .alert-box ul li div p { font-size:14px; line-height:1.5;  }
.header-user .top .alert-box ul li div span { font-size:12px; color:var(--font-color3); float: left; }
.header-user .top .alert-box ul li div strong { position: absolute; left: 15px; top:8px; }
.header-user .top .alert-box ul li div.type2 { background: url(../img/common/ico_tip_arr.png) no-repeat right 50%; background-position: right 15px center;}
.header-user .top .alert-box ul li div.type2 strong  {  color:var(--point-color2);}
.header-user .top .alert-box ul li div button.type3 { width: 25px; text-align: center; float: right; color: var(--font-color4);}

.header-user .top .alert-box .btn-close { position: absolute; right:15px; top:5px; background: url(../img/common/ico_top_tip_close.png) no-repeat 50% 50%; width:30px; height:30px;}
.header-user .top .btn-search { width:20px; height:20px; background: url(../img/common/ico_top_search.png) no-repeat 50% 50%; }
.header-user .top .btn-menu { width:20px; height:20px; background: url(../img/common/ico_top_menu.png) no-repeat 50% 50%; }
.header-user .top .gnb { position:absolute; right:0; top:54px; border-radius:10px; box-shadow:5px 5px 15px rgba(0,0,0,0.15); z-index:100; padding:15px; background-color: #fff; min-width:200px;  transition: all 0.5s ease; opacity:0; visibility: hidden;}
.header-user .top .gnb h2 { font-size:16px; font-weight:700; margin-bottom:10px; padding-left:0; line-height: 1; }
.header-user .top .gnb nav { padding: 10px 0;}
.header-user .top .gnb nav ul { display: flex; flex-direction: column; gap:10px; }
.header-user .top .gnb nav a { display:block; line-height:2.2; }
.header-user .top .gnb .ver { border-bottom:1px solid var(--line-color4); color: var(--font-color3); padding-bottom: 10px; }
.header-user .top .gnb .etc { padding-top:10px; border-top:1px solid var(--line-color4); }
.header-user .top .gnb .etc a { font-size:15px; }
.header-user .top .gnb .etc i { font-size:20px; vertical-align: middle; margin:-3px 5px 0 0;}
.header-user .top .menu.active .btn-menu,
.header-user .top .menu .btn-menu:hover { background-image: url(../img/common/ico_top_menu_on.png); }
.header-user .top .menu.active .gnb { opacity:1; top:44px; visibility: visible; }
.header-user .top .user-profile { width:36px; height:36px; background:url(../img/common/ico_top_user.png) no-repeat 0 0;}
.header-user .top .user-profile img { width:36px; height:36px; border-radius:100%; display:none; }
.header-user .top .user-name { color:var(--font-color4)}
.header-user .top .select{  height:35px; }

.aside-user { position:fixed; left: 0; top:0; bottom:0; width:90px; border-right:1px solid var(--line-color1);  z-index:100;  box-shadow:0 0 10px rgba(0,0,0,0.1); background:#fff;}
.aside-user h1 { height:50px; background: url(../img/common/logo.png) no-repeat 50% 50%;}
.aside-user nav { height: calc(100vh - 50px); overflow-y: auto; }
.aside-user nav::-webkit-scrollbar { display: none; }
.aside-user nav ul li { transition: all 0.5s ease;}
.aside-user nav ul li button { display:block; text-align:center; width:100%; padding:10px 0; color:var(--font-color3); margin: 0 auto; }
.aside-user nav ul li button:before { content:''; display:block; height:24px; margin-bottom: 6px; background: no-repeat 50% 50%; background-size: 24px; }
.aside-user nav ul li button.nav1:before { background-image: url(../img/common/ico_nav1_off.png); }
.aside-user nav ul li button.nav2:before { background-image: url(../img/common/ico_nav2_off.png); }
.aside-user nav ul li button.nav3:before { background-image: url(../img/common/ico_nav3_off.png); }
.aside-user nav ul li button.nav4:before { background-image: url(../img/common/ico_nav4_off.png); }
.aside-user nav ul li button.nav5:before { background-image: url(../img/common/ico_nav5_off.png); }
.aside-user nav ul li button.nav6:before { background-image: url(../img/common/ico_nav6_off.png); }
.aside-user nav ul li button.nav7:before { background-image: url(../img/common/ico_nav7_off.png); }
.aside-user nav ul li button.nav8:before { background-image: url(../img/common/ico_nav8_off.png); }
.aside-user nav ul li button.nav9:before { background-image: url(../img/common/ico_nav9_off.png); }
.aside-user nav ul li button.nav10:before { background-image: url(../img/common/ico_nav10_off.png); }
.aside-user nav ul li button.nav11:before { background-image: url(../img/common/ico_nav11_off.png); }
.aside-user nav ul li.active,
.aside-user nav ul li:hover  { background-color: #E6F0FF;}
.aside-user nav ul li.active button.nav1:before { background-image: url(../img/common/ico_nav1_on.png);}
.aside-user nav ul li.active button.nav2:before { background-image: url(../img/common/ico_nav2_on.png);}
.aside-user nav ul li.active button.nav3:before { background-image: url(../img/common/ico_nav3_on.png);}
.aside-user nav ul li.active button.nav4:before { background-image: url(../img/common/ico_nav4_on.png);}
.aside-user nav ul li.active button.nav5:before { background-image: url(../img/common/ico_nav5_on.png);}
.aside-user nav ul li.active button.nav6:before { background-image: url(../img/common/ico_nav6_on.png);}
.aside-user nav ul li.active button.nav7:before { background-image: url(../img/common/ico_nav7_on.png);}
.aside-user nav ul li.active button.nav8:before { background-image: url(../img/common/ico_nav8_on.png);}
.aside-user nav ul li.active button.nav9:before { background-image: url(../img/common/ico_nav9_on.png);}
.aside-user nav ul li.active button.nav10:before { background-image: url(../img/common/ico_nav10_on.png);}
.aside-user nav ul li.active button.nav11:before { background-image: url(../img/common/ico_nav11_on.png);}

.footer { background:var(--background-color2); padding:30px 20px;  border-top:1px solid var(--line-color1); margin:20px -20px -20px; }
.footer .inner { position:relative; padding-left:300px; }
.footer .inner .logo { position:absolute; left:0; top:0;  }
.footer .inner .logo:before {content:''; display:block ;width:111px; height:73px; background: url(../img/common/foot_logo.png) no-repeat 0 0;}
.footer .inner .logo img { display:none; }
.footer .inner .logo p { font-size:14px; margin-top:15px; opacity:0.7; }
.footer .inner  .menus { display:flex; margin-bottom:15px;   }
.footer .inner  .menus a { font-size:14px;opacity:0.7; margin-right:10px; }
.footer .inner  .menus a:first-child { opacity:1; font-weight:700; }
.footer .inner .info  { font-size:14px; line-height:1.4; color: rgba(0,0,0,0.7); }
.footer .inner .info  span { margin:0 8px; }

.mypage { max-width:900px; margin:0 auto;}
.mypage.align-items-center { height:calc(100vh - 90px); display:flex; }
.mypage .tabs3 { margin-left:0; margin-bottom:20px; }

.dragdrop { min-height:inherit; border-radius:10px; border:1px dashed var(--line-color2); background-color: var(--background-color2); text-align: center; padding:15px; font-size:17px; list-style: 1.5; color:var(--font-color3);}
.dragdrop span { display:block;margin:15px auto 0; width:50%; height:45px; line-height:45px; border-radius:5px; background: var(--line-color1); color:var(--font-color2); }
.dragdrop em { font-size:14px; color:var(--font-color4); }
.dragdrop em i { font-style:normal; color:var(--point-color1); text-decoration: underline; cursor: pointer;}
.dragdrop-wrap { padding:15px; border-radius:10px; border:1px solid var(--line-color1); margin-bottom:20px; }
.dragdrop-wrap .row { margin-bottom:0;}
.dragdrop-wrap .col { display:flex; align-items: center; justify-content: center}
.dragdrop-wrap .dragdrop { width:100%; height:100%; display:flex; align-items: center; justify-content: center;}
.dragdrop-wrap .dragdrop .bd-color {border-color: #2F61D5;}
.dragdrop-wrap .dragdrop .box { width:100%; }
.dragdrop-wrap .add-list { width:100%; margin-top:0; }
.dragdrop .tit { margin:15px 0 5px; font-size:12px; color:var(--font-color4)}

/* 치아관련 디자인 요소 */
.tooth-wrap1 { display:flex; align-items: center; padding:10px 15px; border:1px solid transparent; border-radius:10px; position: relative; cursor: pointer; transition: all 0.5s ease;; }
.tooth-wrap1.active { border-color:#111; }
.tooth-wrap1.active .tooth-box2 .btn-close { display:block; }
.tooth-box1 { width:300px; height:80px;position: relative; }
.tooth-box1 .line:before { content:''; position:absolute; left: 0; right:0; top:50%; height:1px; background-color: #b3b3b3; }
.tooth-box1 .text { position: absolute; width:300px; display:flex; justify-content: center; height:40px; left:0; align-items: center;}
.tooth-box1 .text .box { width:100%;display:flex; justify-content: center;  align-items: center;  flex-wrap: wrap; word-break: break-all;}
.tooth-box1 .top { top:0}
.tooth-box1 .bottom { bottom:0}
.tooth-box1 .text .bridge { background-color: #FFFAF0; padding:0 10px;  line-height:15px; border:1px solid #111; border-radius:20px; }
.tooth-box1 .text .single { padding:0 10px; line-height:15px; }
.tooth-box2 { padding:10px; }
.tooth-box2 .txt p { display: inline-block; padding:0 10px; height:45px; line-height: 43px; border:1px solid var(--line-color1); font-size:15px; color:#c8cbdf; border-radius:5px; background-color:var(--background-color2); color:#111; font-weight:700; border-color:#111;}
.tooth-box2 .btn-close { position: absolute; right:-10px;top:-10px; width:20px; height:20px; border-radius:3px;background:var(--point-color3) url(../img/common/ico_del.png) no-repeat 50% 50%; display:none;}
.tooth-box3 { text-align: center; }
.tooth-box3 .txt { margin-top:15px; color:var(--font-color4); }
.tooth-wrap2 { display: flex; gap:20px; align-items: flex-start; margin-bottom:30px;}
.tooth-wrap2 .btn-add { height:50px; padding:0 15px; border-radius:5px; background:#f2f2f2; border:1px solid #ddd; font-size:14px; transition: all 0.5s ease;}
.tooth-wrap2 .btn-add:hover { border-color:var(--point-color1); color:#fff; background-color:var(--point-color1)}
.tooth-wrap2 .tooth-list3 { flex:1}
.tooth-wrap2 .tooth-list3 ul { height: auto; overflow:visible;}
.tooth-wrap2 .tooth-list3 ul li { width:33.3333%; }
.tooth-list3 .shade-list ul { height: auto; }
.tooth-list3 .shade-list ul li { width:100%; }

.tooth-list3 ul { display:flex; flex-wrap:wrap; margin:0 -7px; gap:7px 0; height:115px; overflow:auto; align-content: baseline; }
.tooth-list3 ul li { width:50%; padding:0 7px; position:relative}
.tooth-list3 ul li.active > div { background-color: #4C90F6; }
.tooth-list3 ul li .btn-del { position:absolute; right:-5px; top:-12px; width:24px; height:24px; background:url(../img/common/ico_del2.svg) no-repeat 50% 50%; z-index:10; display:none;}
.tooth-list3 ul li .btn-del:hover { background-image: url(../img/common/ico_del2_on.svg);}
.tooth-list3 ul li:hover .btn-del { display:block; }
.tooth-list3.type2 ul { margin:0; display:block; height:auto; }
.tooth-list3.type2 ul li { width:100%; }
.tooth-list3.type2 ul li + li { margin-top:10px;}
.tooth-list3.cursor .tooth-list-item { cursor: pointer;}

.tooth-list3 .tooth-list-item { min-height:44px; height:100%; align-items: center; padding:5px 15px;  font-size:14px; display:flex;  position:relative; background-color: #fff;justify-content: space-around;}
.tooth-list3 .tooth-list-item.cP { border: 2px solid #c8e5a1; border-radius: 5px; }
.tooth-list3 .tooth-list-item.cC { border: 2px solid #f9c5f2; border-radius: 5px; }
.tooth-list3 .tooth-list-item.cN { border: 2px solid #89B5F9; border-radius: 5px; }
.tooth-list3 .tooth-list-item.cI { border: 2px solid #babbe6; border-radius: 5px; }

.pmDetail .tooth-list-item { height:100%; align-items: center; padding:5px 15px; display:flex; position:relative; background-color: #fff;justify-content: space-around;}

.tooth-list-item:before { content:''; position:absolute; left:0; top:0; right:0; bottom:0;border:1px solid #ddd; border-radius:5px;}
.tooth-list-item:hover:before { border-color: #4C90F6;}
.tooth-list-item.active { background-color: #EDF4FE;}
.tooth-list-item.active:before { border:2px solid  #4C90F6;}
.tooth-list-item.disabled { background-color:var(--background-color3); opacity:0.5; border-radius:5px;}
.tooth-list-item.disabled:before { border-color:var(--background-color3);}
.tooth-list-item h4 {  font-weight:600; margin-right:15px; white-space: nowrap; }
.tooth-list-item div { display:flex; flex-wrap:wrap; gap:5px; font-size:13px;  align-items: center; justify-content: center;  flex:1;}
.tooth-list-item div p { padding:2px 3px; background:#FFFAF0; border:1px solid #000; border-radius:4px; }

.h3 + .shade-wrap { margin-top:-15px;}
.shade-wrap { display: flex; align-items: center; position: relative; padding-right:200px; }
.shade-wrap .btns{ display:flex; justify-content: right; flex-direction: column; flex: 0 0 200px;  }
.shade-wrap .btns button { text-align: center; font-size:17px; color:var(--font-color3); line-height:50px; border-radius:25px 0 0 25px; margin-bottom:5px; text-align:right; padding-right:15px; transition: all 0.5s ease;; }
.shade-wrap .btns button.active { color:#111; font-weight: 700; font-size:20px;}
.shade-wrap .choices { padding:10px 15px; border-radius:10px; }

.tooth-list1 { width:285px; display:flex; flex-wrap: wrap; gap:10px;}
.tooth-list2 { display:flex; align-items: flex-end; gap:30px; }
.tooth-list2 .item { text-align:center;}
.tooth-list2 .item .box  {display:flex; align-items: flex-end; gap:10px; }
.tooth-list2 .item .box ul { display:flex; gap:10px; flex-direction: column; }
.tooth-list2 .item .tit { font-size:15px; font-weight:600; margin-top:15px; }



.checkbox-tooth { position: absolute; opacity:0;  }
.checkbox-tooth + div { width:39px; height:34px; line-height:32px; border-radius:5px; text-align:center; border:1px solid #c8cbdf; font-size:13px; color:var(--font-color4);  cursor: pointer; transition: all 0.5s ease;}
.checkbox-tooth:checked + div,
.checkbox-tooth:hover + div { background-color:var(--point-color1) ; font-weight:700; color:#fff;}
.checkbox-tooth:disabled + div { background-color: var(--background-color3); cursor: no-drop;}


.tooth-divide { text-align: center; /*position: absolute; right:0; top:0; */ margin-left:40px; margin-top:-30px;}
.tooth-divide .div-btns { margin-bottom:10px }
.tooth-divide .div-btns button.active { font-size:16px; color:#111; font-weight:700; border-bottom: 1px solid #111; }
.tooth-divide .div-btns button + button { margin-left:10px; }
.tooth { margin-bottom:10px; position: relative; cursor: pointer;}
.tooth svg { vertical-align: top; position: relative; z-index:2;}
.tooth.small { width:72px; margin:0 auto; }
.tooth.small svg { width:100%; height:auto }
.tooth.small .selected p { font-size:13px; }
.tooth.mini { width:30px;}
.tooth.mini svg { width:100%; height:auto }
.tooth.mini .selected p { font-size:10px; }
.tooth-choice-wrap { width:1100px; margin: 0 auto;}
.tooth-strike  { margin-bottom:15px; text-align: center; }
.tooth-strike .in-box { display:inline-block; vertical-align: top; position:relative;  }
.tooth-strike .rounder-box { position: absolute; left:-10px; top:-5px; height:80px; background-color: #eeeeee; border:1px solid #545871; border-radius:10px; }
.tooth-strike g { cursor: pointer; position: relative; z-index:2;}
.tooth-strike .viewer { cursor: default; }
.tooth-strike path { transition: all 0.5s ease;}
/* .tooth-strike path:hover, */
.tooth-strike path.cP.active{ fill:#c8e5a1; }
.tooth-strike path.cC.active{ fill:#f9c5f2; }
.tooth-strike path.cN.active{ fill:#89B5F9; }
.tooth-strike path.cI.active{ fill:#babbe6; }
.tooth-strike path.cX.active{ fill:#ff8696; }
.tooth-strike g text { pointer-events: none;}
.tooth-strike .bridge-box button { position:absolute; left:0; top:0; width:24px; height:24px; border-radius:100%; background-color: #545871; z-index:10; display: none;}
.tooth-strike .bridge-box button:before { content:''; position: absolute; left: 50%; top:50%; width:14px; height:2px; transform: translate(-50%,-50%);  background:#fff;}
.tooth-strike .bridge-box button.btn-refresh:before { left:0; top:0; transform: translate(0); width:100%; height: 100%; background: url(../img/common/ico_re.png) no-repeat 50% 50%;}

.ordercheck-wrap { display:flex;  gap:20px}
.order-data { flex:1; }
.order-wrap .user { display: flex; justify-content: space-between; margin-bottom:20px; height:40px; }
.order-wrap .user h3 { font-size:16px; display:flex; gap:15px; align-items: center;}
.order-wrap .user h3 strong { font-size:22px; font-weight:700; }
.order-wrap .user .date { display:flex; align-items: center; gap:15px; font-size:16px; }
.order-wrap .user .date strong { padding:5px 15px; border-radius:30px; font-size:18px; font-weight:700; background-color: var(--background-color3);}
.order-wrap .user .date .btn-memo { background:url(../img/common/ico_memo_off.svg) no-repeat 50% 50%; width:25px; height: 25px; }
.order-wrap .user .date .btn-memo:hover { background-image: url(../img/common/ico_memo_on.svg);}
.order-wrap .user .date .btn-close { background:url(../img/common/ico_print_close.svg) no-repeat 50% 50%; width:25px; height: 25px;}

.order-data .user { display: flex; justify-content: space-between; margin-bottom:20px; height:40px; }
.order-data .user h3 { font-size:14px; display:flex; gap:15px; align-items: center;}
.order-data .user h3 strong { font-size:16px; font-weight:700; }
.state-M { font-weight:700; color: var(--state-color1); }
.state-R { font-weight:700; color: var(--state-color1); }
.state-A { font-weight:700; color: var(--state-color1); }
.state-D { font-weight:700; color: var(--state-color2); }
.state-F { font-weight:700; color: var(--state-color2); }
.state-W { font-weight:700; color: var(--state-color2); }
.state-P { font-weight:700; color: var(--state-color3); }
.state-K { font-weight:700; color: var(--state-color3); }
.state-S { font-weight:700; color: var(--state-color3); }
.state-C { font-weight:700; color: var(--state-color4); }

.order-data .user > h3 .btn-ico { color:var(--font-color3); transition: all 0.5s ease;}
.order-data .user > h3 .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}

.order-data .user .date { display:flex; align-items: center; gap:15px; font-size:14px; }
.order-data .user .date strong { padding:5px 15px; border-radius:30px; font-size:16px; font-weight:700; background-color: var(--background-color3);}
.order-data .user .date .btn-memo { background:url(../img/common/ico_memo_off.svg) no-repeat 50% 50%; width:25px; height: 25px; }
.order-data .user .date .btn-memo:hover { background-image: url(../img/common/ico_memo_on.svg);}
.order-data .user .date .btn-close { background:url(../img/common/ico_print_close.svg) no-repeat 50% 50%; width:25px; height: 25px;}

.order-data .box { display: flex; align-items: center; padding:15px 20px; border:1px solid var(--line-color2); border-radius:10px; gap:40px; margin-bottom: 20px; clear:both;}
.order-data .box .tit {  text-align: center; }
.order-data .box .tit .types{ display:flex; gap:20px; color:var(--font-color4) }
.order-data .box .tit .types p span { margin-left:5px; }
.order-data .box .info .section { display: flex; flex-wrap: wrap; gap:15px; }
.order-data .box .info .section + .section { margin-top:20px; }
.order-data .box .info .section dl { padding-right:40px; margin-right:10px; }
.order-data .box .info .section dl:last-child { margin-right:0; }
.order-data .box .info .section dl dt { width:fit-content; background-color:#F8FBFF; margin-top:-10px; color:var(--font-color3); padding: 0 5px;}
.order-data .box .info .section dl dd { font-size:14px; }
.order-data .box .info .section .box.type4 { margin:auto; width:45%; padding: 0px 20px; height:45px; border: 1px dashed var(--line-color5); background-color:#F8FBFF; text-align: center; }
.order-data .box .info  { display:flex; flex-direction: column; justify-content: center;}
.ordercheck-wrap .order-data .box .info { transform: translateY(-5px); }
.order-data .box .tooth-box3 { margin:0 50px; }
.order-data .box.block { display:block; padding-top:15px !important; }
.order-data .box textarea { width:100%; height:100px; outline:none; }

.order-modify.item {display:flex; gap:40px; height:45px; }
.order-modify.item dl { display:flex; align-items:center; }
.order-modify.item dl dt { font-size:14px; margin-right:20px; }
.order-modify.item dl dd { flex:1 1 auto; }
.order-modify.item dl dd + dd { margin-left: 10px; }
.order-modify.item dl dd .inp { width:100%; }

.file-box { position: relative; border:1px solid var(--line-color2); border-radius:10px; padding:10px 20px; height:170px; margin-bottom:30px; background-color: #fff;}
.file-box .file-list { height: calc(100% - 50px); width:100%; overflow:auto; white-space: nowrap; }
.file-box .file-list li { margin-bottom:5px; font-size:15px; color:var(--font-color3); }
.file-box .file-list li > span:hover { color:var(--point-color1); cursor: pointer;}
.file-box .file-list li .viewer { margin-left: 10px; background:url(../img/common/ico_viewer.svg) no-repeat 50% 50%; width:20px; height: 20px; cursor: pointer;}
.file-box .file-list li > small { margin-right: 10px; }
.file-box .file-list li .btn-ico { margin: 0 8px; font-size:14px; color:var(--font-color3); transition: all 0.5s ease;}
.file-box .file-list li .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}

.file-box .h4 { margin-bottom:10px; display: flex; justify-content: space-between; align-items: center;}
.file-box .h4 .btn-ico { font-size:25px; color:var(--font-color3); transition: all 0.5s ease;}
.file-box .h4 .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}

.file-box .upload-rescan { top: 0; left: 0; border-radius: 10px; -webkit-backdrop-filter: blur(1px); backdrop-filter: blur(1px); box-shadow: 2px 2px 4px 4px rgba(255, 113, 113, 0.3); width: 100%; height: 100%; position: absolute; background-color: rgba(255, 113, 113, 0.3); }
.file-box .upload-rescan div { display: flex; justify-content: center; align-items: center; flex-direction:column; height: 100%; }
.file-box .upload-rescan .btn-ico { font-size:30px; color:var(--font-color3); transition: all 0.5s ease;}
.file-box .upload-rescan .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}
.file-box .upload-rescan label { color:var(--font-color3); font-size: 18px; transition: all 0.5s ease;}
.file-box .upload-rescan label:hover { color:var(--point-color1); transform: translateY(3px); }

.ordercheck-wrap .order-etc { flex:0 0 300px; }
.ordercheck-wrap .order-etc > .h4 { height:50px; }
.ordercheck-wrap .order-etc .h4.mb5 { height:auto; }
.ordercheck-wrap .order-etc .textarea {  border:1px solid var(--line-color2); border-radius:10px; }
.ordercheck-wrap .text { height: 100px; }
.ordercheck-wrap .text .textarea { background-color: #fff; height: 100%; overflow: auto; padding: 5px; }
.ordercheck-wrap .text .len { bottom: 6px !important; }

.order-writeFrame { display: flex; gap: 20px;}
.order-writeFrame .side { flex: 1;}
.order-writeFrame .side .sideDiv{ display: flex; justify-content: center; align-items: center; height: 500px; max-width: 100px; overflow: hidden; }
.order-writeFrame .side .sideDiv.right{ margin-right: auto;}
.order-writeFrame .side .sideDiv.left{ margin-left: auto;}
.order-writeFrame .middle { flex: 0 0 1100px;}
.order-writeFrame .text { position: relative; }
.order-writeFrame .text .len { position:absolute; right:16px; bottom:12px; font-size:10px; color:var(--font-color4); opacity : 0.8; }

.required-txt { display: flex; border:1px dashed var(--line-color2); border-radius:5px; }
.required-txt strong { display: flex; align-items: center; justify-content: center; padding:5px 10px; font-weight:700; background:var(--background-color3); border-right:1px dashed var(--line-color2);}
.required-txt p { padding:5px 10px;}
.required{color: red; font-family: 'Roboto';}

.check-bottom { display: flex; border-top:1px solid var(--line-color1); padding-top:15px; margin-top:20px; }

.total-price { flex: 1; display:flex; justify-content: flex-start; gap:20px; align-items: center; }
.total-price em { font-size:17px; color:var(--font-color3); }
.total-price div { font-size:17px; }
.total-price div strong  {font-size:24px; font-weight: 700;}

.lab-name:hover { cursor: pointer; }

.check-button { flex: 1; display:flex; justify-content: flex-end}

.complete-msg-box { background:#fff; width:100%; border:1px solid var(--line-color4); border-radius:10px; display:flex; align-items: center; justify-content: center; }
.complete-msg-box .complete-msg { margin:0; }
.complete-msg { width: 400px; background-color: var(--background-color4); text-align:center; margin:10px; padding: 30px 10px; border-radius: 20px;}
.complete-msg:hover { background-color: var(--background-color5);}
.complete-msg .msg { font-size:34px; font-weight:600; margin:20px 0 0 0; color: var(--point-color1);}

.tooth .selected { position: absolute; left: 0; right:0; top:0; height: 100%;; display: flex; flex-direction: column ; z-index:3; }
.tooth .selected p { flex:1; font-size:16px; text-align: center; font-weight: 600; display: flex; align-items: center; justify-content: center;} 
.tooth g.on path { fill:#FFEDC8; }

/* 홈화면 */
.home-wrap { display:flex; gap:15px; height:calc(100vh - 50px);  margin:-20px; padding:20px 50px 20px 20px; background:#fff;  min-height:700px;}
.home-wrap .order-card { width:280px; border-radius:10px; border:1px solid var(--line-color4); background-color: #fff; overflow: hidden;} 
.home-wrap .order-card .head { padding:20px 10px; position: relative; border-bottom:1px solid var(--line-color4); border-bottom:0;}
.home-wrap .order-card .head .btn-orderadd { width:100%;height:55px; text-align:center; font-size:18px; color:var(--point-color1); border:1px solid var(--point-color1); border-radius:5px; font-weight:700;}
.home-wrap .order-card .head .btn-orderadd i { font-size:22px; vertical-align: middle; margin:-4px 5px 0 0; }
.home-wrap .order-card .head .search-box { display: flex; gap:10px; margin-top:15px; justify-content: flex-end; }
.home-wrap .order-card .head .search-box .search { position:relative; }
.home-wrap .order-card .head .search-box .search .inp { width:100%; height:40px; padding:0 45px 0 10px; font-size:14px;  }
.home-wrap .order-card .head .search-box .search .btn-search { position:absolute; right:0; top:0; height:40px; width:40px; background: url(../img/common/ico_search.png) no-repeat 50% 50%;}
.home-wrap .order-card .head .search-box .btn-filter { font-size:22px; color:var(--font-color4); }
.home-wrap .order-card .head .search-box .btn-filter.active { color:var(--point-color1)}
.home-wrap .order-card .body { position:relative; height: calc(100% - 150px); overflow: auto; }
.home-wrap .order-card .body .list-box { padding-bottom:15px; }
.home-wrap .order-card .body .list-box .btn-flip { width:100%; height:45px; padding-left:10px; font-size:15px; position:relative; text-align:left; border-bottom:1px solid var(--line-color4);  background-color: var(--background-color2); }
.home-wrap .order-card .body .list-box .btn-flip:before { content:''; position:absolute; right:10px; top:50%; margin-top:-4px; width: 0;	height: 0;	border-style: solid;	border-width: 8px 5px 0 5px;	border-color: #b9b9b9 transparent transparent transparent; transition: all 0.5s ease;}
.home-wrap .order-card .body .list-box .btn-flip em { position:absolute; right:25px; top:50%; margin-top:-12px; width:24px; height:24px; border-radius:100%; line-height:24px; text-align:center; color:var(--point-color2); font-size:15px; background-color: var(--background-color3); }
.home-wrap .order-card .body .list-box .btn-flip.active { color:#111; font-weight:700;}
.home-wrap .order-card .body .list-box .btn-flip.active:before { transform: rotate(180deg); border-top-color:#111; }
.home-wrap .order-card .body .list-box ul { padding:10px; border-bottom:1px solid var(--line-color4);}
.home-wrap .order-card .body .list-box ul li { margin-bottom:10px; }
.home-wrap .order-card .body .list-box ul li .item { min-height:122px; border-radius:10px; border:1px solid var(--line-color4); padding:10px; box-shadow:3px 3px 7px rgba(0,0,0,0.05); position: relative; cursor: pointer; }
.home-wrap .order-card .body .list-box ul li .item:before { content:''; position:absolute; left:-1px; right:-1px; top:-1px; bottom:-1px; border-radius:10px; border:2px solid var(--point-color1); opacity:0; visibility: hidden; transition: all 0.5s ease;}
.home-wrap .order-card .body .list-box ul li .item .title { display:flex; justify-content: space-between; font-size:15px;  margin-bottom:4px; } 
.home-wrap .order-card .body .list-box ul li .item .title strong { font-weight:400;}
.home-wrap .order-card .body .list-box ul li .item .title span { color:var(--font-color4); text-align:right; }
.home-wrap .order-card .body .list-box ul li .item .user { display:flex; justify-content: space-between; align-items: center; margin-bottom:8px; }
.home-wrap .order-card .body .list-box ul li .item .user .name { font-size:16px; font-weight:700; word-break: break-all;}
.home-wrap .order-card .body .list-box ul li .item .user .date { text-align:center; min-width: 75px;}
.home-wrap .order-card .body .list-box ul li .item .user .date strong { font-size:17px; }
.home-wrap .order-card .body .list-box ul li .search { text-align: center; padding: 15px; }
.home-wrap .order-card .body .list-box ul li .search img { width:30px; height:30px; }
.home-wrap .order-card .body .list-box ul li .search .empty { font-size: 15px; margin-top: 20px; }
.home-wrap .order-card .body .list-box ul li .search .retry { font-size: 14px; color: #555; }
.home-wrap .order-card .body { overflow:hidden; }
.home-wrap .order-card .body .list-box { padding-bottom:0; position:absolute; left: 0; right:0; top:0; bottom:15px;border-top:1px solid var(--line-color4);}
.home-wrap .order-card .body .list-box ul { height:calc(100% - 74px); overflow:auto; border-top:0; border-width: 1px 1px 1px 1px;}
.home-wrap .order-card .body .list-box ul:nth-last-child(1) { height:calc(100% - 90px); overflow:auto; border-top:0; border-width: 1px 1px 0 1px;}
.home-wrap .order-card .body .list-box.scroll6 ul { height:calc(100% - 45px * 6); }

.tags{ display:flex; flex-wrap: wrap; gap:10px;}
.tags p { padding:0 4px; height:16px; line-height:16px; background:#FFF5F5; font-size:12px; border-radius:3px;}
.tags p i { color:#00C389; font-size:14px; vertical-align: middle; margin:-2px 0 0 4px;}
.tags .tag1 { background:#FFF5F5; color:#FF5353}
.tags .tag2 { background:#FAECFC; color:#CF49E4}
.tags .tag3 { background:#FFEFE0; color:#FB8110}
.tags .tag4 { background:#D9E8FF; color:#4C90F6}
.tags .tag5 { background:#efffee; color:#66CC00}
.tags .tag-A { background:#FFF5F5; color:#FF5353} 
.tags .tag-P { background:#efffee; color:#66CC00}
.chat-group { display: flex; justify-content: space-between; }
.home-wrap .order-card .body .list-box ul li .item:hover { background-color: var(--background-color4);}
.home-wrap .order-card .body .list-box ul li .item.active:before { opacity:1; visibility: visible; }
.home-wrap .order-card .body .list-box ul li .item.state-M { border-bottom: 5px solid var(--state-color1); }
.home-wrap .order-card .body .list-box ul li .item.state-R { border-bottom: 5px solid var(--state-color1); }
.home-wrap .order-card .body .list-box ul li .item.state-A { border-bottom: 5px solid var(--state-color1); }
.home-wrap .order-card .body .list-box ul li .item.state-D { border-bottom: 5px solid var(--state-color2); }
.home-wrap .order-card .body .list-box ul li .item.state-F { border-bottom: 5px solid var(--state-color2); }
.home-wrap .order-card .body .list-box ul li .item.state-W { border-bottom: 5px solid var(--state-color2); }
.home-wrap .order-card .body .list-box ul li .item.state-K { border-bottom: 5px solid var(--state-color3); }
.home-wrap .order-card .body .list-box ul li .item.state-S { border-bottom: 5px solid var(--state-color3); }
.home-wrap .order-card .body .list-box ul li .item.state-C { border-bottom: 5px solid var(--state-color4); }

.home-wrap .order-card .filter-box { display:none; position: absolute; left: -1px; top:85px; right:-1px; border-radius:0 0 10px 10px; box-shadow:0 10px 10px rgba(0,0,0,0.1); z-index:10; background:#fff; border:1px solid var(--line-color4); padding:10px; }
.home-wrap .order-card .filter-box .check-list { display: flex; flex-wrap:wrap; }
.home-wrap .order-card .filter-box .check-list li { width:50%; }
.home-wrap .order-card .filter-box .radio-list { display: flex ;}
.home-wrap .order-card .filter-box .radio-list li { width:20%; }
.home-wrap .order-card .filter-box .radio-list li label { display: block;}
.home-wrap .order-card .filter-box .radio-list .radio { }
.home-wrap .order-card .filter-box .radio-list .radio + div { display:flex; text-align:center;flex-direction: column-reverse; justify-content: center;}
.home-wrap .order-card .filter-box .radio-list .radio + div p { display:block; margin-left:0; }
.home-wrap .order-card .filter-box hr { margin:15px 0;}

.home-main-wrap { flex:1; display:flex;  gap:15px;  min-width:0;  height: 100%;}
.home-main-box { flex:1; display:flex;  flex-direction: column;min-width:0; }
.home-main { flex:1; display:flex; padding:15px; border-radius:10px; background:var(--background-color2); border:1px solid var(--line-color4);gap:14px; height: 100%;}
.home-nav { display:flex; justify-content: space-between; align-items: center; border-radius:10px; background-color: var(--background-color1); padding:0 15px; margin-top:15px;}
.home-nav .btn i { font-size:20px;vertical-align: middle; margin:-2px 5px 0;}
.home-nav .btn span + i {margin-right:0;}
.home-nav .btn i:first-child {margin-left:0;}
.home-main .section { border:1px solid var(--line-color4); border-radius:10px; background-color: #fff; flex:1; }
.home-main .section .head { height:50px; display: flex; align-items: center; justify-content: space-between; padding:0 15px; border-bottom: 1px solid var(--line-color4); ; position:relative;}
.home-main .section .head h3 { margin-bottom:0; font-size:14px; }
.home-main .section .head .btns { position: absolute; right:10px; top:0; }
.home-main .section .head .btns .btn { padding:0 5px;}
.home-main .section .body { height:calc(100% - 50px); overflow: auto;}
.home-main .section .body .table1 table thead th  { border-top:0; }
.home-main .section .body.chart { display: flex; align-items:center; padding:5px; }
.notice-list li button { display:block; padding:0 80px 0 15px; line-height:40px;  border-bottom:1px solid var(--line-color4); position:relative;  font-size:15px;  transition: all 0.5s ease; width:100%; text-align:left;  }
.notice-list li button .noti { position:absolute; left: 0; width:80px; top:0; line-height:40px; font-weight:700;text-align: center; font-style:normal; }
.notice-list li.active button { background-color: var(--background-color5); padding-right:10px;}
.notice-list li button p {white-space: nowrap;overflow:hidden; text-overflow: ellipsis;  font-weight: 400;}
.notice-list li button span { position:absolute; right:15px; top:0; color:var(--font-color4); }
.home-main .section .memo-list li a { display:block; padding:0 160px 0 15px; height:60px; display:flex; align-items:center; white-space: nowrap; border-bottom:1px solid var(--line-color4); position:relative;  font-size:15px; overflow:hidden; text-overflow: ellipsis; transition: all 0.5s ease;}
.home-main .section .memo-list li a:hover { background-color: var(--background-color5);}
.home-main .section .memo-list li a .etc { position:absolute; right:15px; top:50%; transform: translateY(-50%); color:var(--font-color4); display:flex; gap:10px; font-weight:400; }
.home-main .section .memo-list li a .etc em:after { content:''; display:inline-block; vertical-align: middle; width:7px; height:7px; border-left:1px solid var(--point-color3);border-bottom:1px solid var(--point-color3); transform: rotate(-135deg); margin:-3px 0 0 4px;}
.home-main .section .memo-list li a .name { width:100px; }
.home-main .section .memo-list li a .name small { display:block; font-size:12px;  }
.home-main .section .memo-list li a .txt { flex:1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;  }
.home-main .section .banner { height:100%; }
.home-main .section .banner img { height:100%; width:100%; }
.home-main > div {  }
.home-main > .left { display:flex; justify-content:space-between; flex-direction: column; gap:15px; width:calc(30% - 9px)}
.home-main > .left .section.flex-grow-0 {}
.home-main > .left .row { height:calc(45% - 30px); }
.home-main > .left > .section:last-child { height:35%; }
.home-main > .right { display:flex; justify-content:space-between; flex-direction: column; gap:15px; width:calc(30% - 9px)}
.home-main > .right { }
.home-main > .right .section { flex:1; height: 50%;}
.home-main > .center{width:calc(40% - 9px)} 
.home-main > .center .section { height: 100%; overflow:hidden; }
.home-main .order-data .box { display: flex; border:0; box-shadow:none; border-radius:0; padding:15px; border-bottom:1px solid var(--line-color4) ; min-height:80px; margin-bottom:0; }
.home-main .order-data .box .tit { flex: 1; font-size:12px; }
.home-main .order-data .box .tit { font-size: 14px; color: var(--font-color2);}
.home-main .order-data .box .tit span + span { margin-left:5px;}
.home-main .order-data .info .section { border:0; padding:0;}
.home-main .order-data .box .info .section dl dt { font-size:13px;}
.home-main .order-data .box .info .section dl dd { font-size:15px;}
.home-main .order-data .box .info .section dl + dl { padding-left:10px; margin-left:0; border:0; }
.home-main > .center .order-data { background-color: none; border:0; padding:0; }
.home-main > .center .order-data .box { padding-top:15px;margin-bottom: 0; height: auto;}
.home-main > .center .order-data .box:nth-child(odd) { background-color: #F9FAFD;}
.home-main > .center .order-data .box .tooth-list3.type2  {flex:1; }
.home-main-box .centere .order-data .box .tit .h4 { position: static; text-align: left;}
.home-main .tooth-box1 { width:110px;height:30px; }
.home-main .tooth-box1 .text { width:55px; margin-left:0 !important; }
.home-main .tooth-box1 .text p { height:15px; line-height:13px; font-size:11px; }
.home-main .tooth-box1 .top { top:0; }
.home-main .tooth-box1 .bottom { bottom:0; }
.home-main .calcu { padding:20px; }
.home-main .calcu h4 { font-size:18px; font-weight:700; margin-bottom:8px; }
.home-main .calcu dl { display:flex; justify-content: space-between; }
.home-main .calcu dl dt { font-size:16px; color:var(--font-color3);}
.home-main .calcu dl dd { font-size:20px; color:#000; font-weight:700; }
.home-main .calcu dl + h4 { margin-top:30px; }
.home-main .calcu dl.total { padding-top:30px; margin-top:30px; border-top:1px dashed var(--line-color1); }
.home-side { width:230px; border:1px solid var(--line-color4); border-radius:10px; background-color: #fff; overflow: hidden; }
.home-side .head { height:55px; padding:0 15px; border-bottom:1px solid var(--line-color4); align-items: center; display:flex; justify-content: space-between; }
.home-side .head .h4 { margin-bottom:0;}
.home-side .head .btn-close { background:url(../img/common/ico_print_close.svg) no-repeat 50% 50%; width:25px; height: 25px;}
.home-side .sidememo-list { padding:10px; }
.home-side .sidememo-list li { margin-bottom:10px; }
.home-side .sidememo-list li .item { border:1px solid var(--line-color4); border-radius:10px; padding:10px; box-shadow:3px 3px 7px rgba(0,0,0,0.05); }
.home-side .sidememo-list li .item .btns { display:flex; justify-content: flex-end; gap:3px; }
.home-side .sidememo-list li .item .btns button { width:15px; height:15px; }
.home-side .sidememo-list li .item .btns button.active .ico-bookmark,
.home-side .sidememo-list li .item .btns button:hover .ico-bookmark{ background-image: url(../img/common/ico_bookmark.png); }
.home-side .sidememo-list li .item .btns button.active .ico-pin,
.home-side .sidememo-list li .item .btns button:hover .ico-pin{ background-image: url(../img/common/ico_pin.png); }
.home-side .sidememo-list li .item .txt { font-size:16px; line-height:1.5; margin:10px 0 5px; word-break: break-all; }
.home-side .sidememo-list li .item .date { text-align:right; font-size:13px; color:var(--font-color4);  }

.home-side .body { height:calc(100% - 55px); }
.chat-wrap { height: 100%; padding-bottom: 40px; position: relative; } 
.chat-wrap .chat-body { position:absolute; left: 0; right:0; top:0; bottom:40px; overflow: auto; padding:14px 10px;}
.chat-wrap .chat-body .date { text-align: center; margin-bottom:30px; }
.chat-wrap .chat-body .date p { display: inline-block; padding:0 10px; height:18px; border-radius:18px; background-color: #D9E8FF; font-size:12px; color:#fff; }
.chat-wrap .chat-body .right { display:flex-end; flex-direction: column; margin-bottom: 13px;;}
.chat-wrap .chat-body .left { display:flex; align-items: baseline; flex-direction: column; margin-bottom: 13px;;}
.chat-wrap .chat-body .name{ font-size:10px; color:#4C90F6; margin-bottom:2px; }
.chat-wrap .chat-body .chat{ padding:8px 6px; border-radius:5px; font-size:12px; border: 1px solid #eee;}
.chat-wrap .chat-input { position:absolute; left: 0; right:0; bottom:0; border-top:1px solid var(--line-color4);  }
.chat-wrap .chat-input input { width:100%; height:39px; border:0; padding:0 50px 0 10px; font-size:13px; }
.chat-wrap .chat-input .btn-send { position:absolute; right:0; top:0; width:40px; height:39px; background: url(../img/common/ico_chat_send.svg) no-repeat 50% 50%; }

.chat-wrap2 { height: 100%; padding-bottom: 40px; position: relative; } 
.chat-wrap2 .chat-body { position:absolute; left: 0; right:0; top:0; bottom:145px; overflow: auto; }
.chat-wrap2 .chat-body .item { border-bottom: 1px solid var(--line-color4); padding:10px;}
.chat-wrap2 .chat-body .item.type-A { background-color: var(--background-color6);}
.chat-wrap2 .chat-body .item.type-P { background-color: #f6ffec; }
.chat-wrap2 .chat-body .item .chat-group .type{  display: inline-block; vertical-align: middle; padding:0 8px; height:16px; border-radius:3px; font-size:12px; color:#fff; }
.chat-wrap2 .chat-body .item .chat-group .type.typeC { background-color: #4C90F6;}
.chat-wrap2 .chat-body .item .chat-group .type.typeL { background-color: #7C7C7C;}
.chat-wrap2 .chat-body .item .chat-group .type.typeQ { background-color: #3FBC97;}
.chat-wrap2 .chat-body .item .chat-group .type.typeD { background-color: #f1b04f;}
.chat-wrap2 .chat-body .item .file { gap: 10px; }
.chat-wrap2 .chat-body .item .text { font-size:14px; color:#333;line-height:1.4; margin:10px 0 12px; }
.chat-wrap2 .chat-body .item .time{ text-align: right; font-size:12px; color:#7D7D7D;}
.chat-wrap2 .chat-input { position:absolute; left: 0; right:0; bottom:0; border-top:1px solid var(--line-color4);  }
.chat-wrap2 .chat-input .len { position:absolute; right:10px; bottom:45px; font-size:10px; color:#999; background:#fff; }
.chat-wrap2 .chat-input textarea { width:100%; height:105px; border:0; padding:10px; font-size:12px; line-height: 1.4; color:#333;  vertical-align: top;  outline:none;}
.chat-wrap2 .chat-input .bt {  height:40px; border-top:1px solid var(--line-color4); display: flex; align-items: center; justify-content: space-between; padding:0 10px; }
.chat-wrap2 .chat-input .bt .btn { height:26px; padding:0 12px; font-size:12px;  }
.chat-wrap2 .chat-input .btn-del { width:20px; height:20px; background:url(../img/common/ico_del2.svg) no-repeat 50% 50%; }
.upload-file input[type="file"] { display: none; }
.upload-file label { background:url(../img/common/ico_clip.svg) no-repeat 50% 50%; width:16px; height: 16px; cursor: pointer; display: inline-block; vertical-align: middle; }

.home-side .sidenoty-list li {  }
.home-side .sidenoty-list li .item { border-bottom:1px solid var(--line-color4); padding:15px; word-break: break-all; }
.home-side .sidenoty-list li .item .type { display:flex; justify-content: space-between; margin-bottom: 10px; font-size:12px; color:var(--font-color4)}
.home-side .sidenoty-list li .item .subj { font-size:14px; }
.home-side .sidenoty-list li .item .btns { text-align: right;}
.home-side .sidenoty-list li .item .btns a { font-size:13px; color:var(--font-color3); }
.home-side .sidenoty-list li .item .btns a i { font-size:18px; vertical-align: middle; margin:-3px 0 0 4px;  transition: all 0.5s ease;}
.home-side .sidenoty-list li .item .btns a:hover { color:var(--point-color1)}
.home-side .sidenoty-list li .item .btns a:hover i { transform: translateX(5px);}
.home-side .sidenoty-list li .item.active { background-color: var(--background-color4);}

.home-main-box .order-data { flex:1;  padding:15px; border-radius:10px; background:var(--background-color2); border:1px solid var(--line-color4); gap:15px;  overflow: auto; }
.home-main-box .order-data .user { position: sticky; top:-15px; z-index:10; width:calc(100% + 30px); margin:-15px -15px 15px;border-bottom: 1px solid var(--line-color1); padding:0 15px; height: 55px; align-items: center; background-color: #fff; border-radius:10px 10px 0 0;} 
.home-main-box .order-data .box { background-color: #fff; position: relative; padding-top:50px; margin-bottom: 15px;;}
.home-main-box .order-data .box.type3 { height:130px; padding-top:15px;  }
.order-data .box.type2 { border-style: dashed; background-color: #F8FBFF;}

.home-main-box .order-data .box .tit .h4 { position:absolute; left: 20px; top:16px; ;}
.home-main-box .order-data .box.block .h4,
.home-main-box .order-data .box .tit .h4.static { position:static; }

.order-data .col-box { display:flex; gap:15px; margin-bottom:15px; }
.order-data .col-box .box { display:block; margin-bottom: 0; }
.order-data .col-box > div { flex:1; min-height: 170px; height: auto; }

.home-main-box .order-data .box .tooth-box3 { margin:0; }
.home-main-box .order-data .col-wrap { display:flex; justify-content: space-between;}
.home-main-box .order-data .col-wrap > .col { width:calc(64% - 15px);}
.home-main-box .order-data .col-wrap > .col + .col{ width:36%;}
.home-main-box .order-data .teethbox{ padding: 5px; width:100%; overflow-x: auto;}
.home-main-box .order-data .col-wrap > .col .box:last-child { margin-bottom:0;}
.home-main-box .order-data .col-wrap > .col .box:last-child .text { word-break: break-all; height:110px; overflow-y: auto;}
.home-main-box .order-data .col-wrap .row { flex-direction: column; gap:15px; height:100%; margin-bottom: 0;}
.home-main-box .order-data .col-wrap .file-box { margin-bottom: 0; flex:auto; }
.home-main-box .order-data .col-wrap .file-box + .file-box { }

/* 기공의뢰서 */
.order-request { position: relative; display: flex; flex-direction: column; padding:35px; border-radius:10px; border:1px solid var(--line-color4); gap:15px; overflow: auto; font-size: 22px; width: 1200px; }
.order-request .title { padding: 40px; font-size: 60px; font-weight: bold; text-align: center; }
.order-request .qrcode { position: absolute; top:50px; right:50px; display: flex; justify-content: flex-end; flex-direction: column; }
.order-request .qrcode p { font-size: 16px; text-align: center; margin-top:5px; }
.order-request .box { display: flex; align-items: center; padding:15px 20px; border:1px solid var(--line-color2); border-radius:10px; gap:40px; margin-bottom: 20px; clear:both;}
.order-request .box.block { display:block; padding-top:15px !important; }
.order-request .box.type2 { border-style: dashed; background-color: #F8FBFF;}
.order-request .box .tit h4{ font-size: 26px; }
.order-request .box .tit .types{ display:flex; gap:20px; color:var(--font-color4) }
.order-request .box .tit .types p span { margin-left:5px; }
.order-request .col-box { display:flex; gap:15px; margin-bottom:15px; }
.order-request .col-box .box { display:block; margin-bottom: 0; }
.order-request .col-box > div { flex:1; min-height: 170px; height: auto; }
.order-request .table2 { font-size:30px; }
.order-request .table2 tbody td { padding: 24px 0; }
.order-request .tooth-list3 ul { height: auto; }
.order-request .tooth-list-item { min-height: 60px; }
.order-request .tooth-list-item h4 { font-size: 24px; }
.order-request .tooth-list-item .shade-list { font-size: 20px; }
.order-request .box .info .section { display: flex; flex-wrap: wrap; gap:15px; }
.order-request .box .info .section + .section { margin-top:20px; }
.order-request .box .info .section dl { padding-right:40px; margin-right:10px; }
.order-request .box .info .section dl:last-child { margin-right:0; }
.order-request .box .info .section dl dt { font-size:20px; width:fit-content; background-color:#F8FBFF; color:var(--font-color3); padding: 0 5px;}
.order-request .box .info .section dl dd { font-size:18px; }
.order-request .box .info .section .box.type4 { display: flex; margin:auto; width:45%; padding: 0px 20px; height:55px; border: 1px dashed var(--line-color5); background-color:#F8FBFF; text-align: center; }
.order-request .box .info .section .box.type4 dt { flex: 0.3 1; }
.order-request .box .info .section .box.type4 dd { flex: 0.7 1; }
.order-request .box .info  { display:flex; flex-direction: column; justify-content: center;}
.order-request .order-comment-list li .type {flex: 0.2 1;}
.order-request .order-comment-list li .type > div {font-size: 16px;}
.order-request .order-comment-list li .text {font-size: 20px;}
.order-request .order-comment-list li .time {font-size: 16px; flex: 0.2 1;}
.order-request .order-comment-list li:last-child {border-bottom: none;}

.mobile-message { height: 100%; width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;}
.mobile-message .h3 {padding: 400px 0; font-size: 7em;}

.home-main-box .re-scan { display:flex; gap:15px; margin-bottom:15px; }
.home-main-box .re-scan button { width:200px; border:1px solid var(--point-color2); font-size:20px; color:var(--point-color2); border-radius:10px; background:var(--background-color4);}
.home-main-box .re-scan .box { display:block;flex:1; margin-bottom:0 !important; padding-top:15px;}
.home-main-box .re-scan .box .text{ margin-left:0 !important; padding:15px; border-radius:10px; background-color: var(--background-color3); }

.month-amount { padding:15px; display:flex; justify-content: space-between; align-items: center; }
.month-amount .totals .h3 { margin-bottom:5px;  font-size:18px;}
.month-amount .totals .price { font-size:20px; }
.month-amount .totals .price strong { font-size:27px; }
.month-amount .totals .before { font-size:14px; color:#B5B5B5; height: 22px; }
.month-amount .goal { width:90px; }
.month-amount .goal h4 { text-align: center; font-size:14px; font-weight:700; color:#4C90F6; margin-bottom: 16px; }
.month-amount .goal .val { height:74px; border-radius:5px; box-shadow:0 3px 10px rgba(0,0,0,0.1); position:relative;}
.month-amount .goal .val div { position:absolute;left:4px; right:4px; bottom:4px; height:70px}
.month-amount .goal .val div p { position:absolute; left: 0; bottom:0; right:0; height:100%; background:linear-gradient(0deg, rgba(129,179,255,1) 0%, rgba(0,102,254,1) 100%); ; border-radius:5px;}
.month-amount .goal .val div p span { position:absolute; left:0; right:0; top:-20px; line-height:20px; text-align: center; font-size:15px; color:#5697F8; }

.status {line-height: 1;}
.order-list2 { padding:10px 20px; height:100%; display:flex; justify-content: space-evenly; flex-direction: column ;}
.order-list2 dl { display:flex; justify-content: space-between; align-items: center; cursor:pointer; }
.order-list2 dl + dl { margin-top:7px; }
.order-list2 dl dt { font-size:14px; color:#999999}
.order-list2 dl dt .tags p{ width:85px; height:20px; line-height:20px; text-align: center;}
.order-list2 dl dd { font-size:20px; color:#000; font-weight:600; }
.order-list2 dl.disabled dd { color:#E3E3E3}
.table1 .tags p{ width:70px; height:20px; line-height:20px; text-align: center;}

.order-comment-list .item { border-bottom: 1px solid var(--line-color4); padding:10px; display: flex; align-items: center; justify-content: space-between; }
.order-comment-list .item .type { flex: 0.15;  text-align: center;}
.order-comment-list .item .type > div { display: inline-block; vertical-align: middle; padding:0 8px; border-radius:3px; font-size:12px; color:#fff; }
.order-comment-list .item .type > div.typeQ { background-color: #3FBC97;}
.order-comment-list .item .type > div.typeC { background-color: #4C90F6;}
.order-comment-list .item .type > div.typeL { background-color: #7C7C7C;}
.order-comment-list .item .text { flex: 1; word-break: break-word; padding: 0 20px; }
.order-comment-list .item .time { flex: 0.15; font-size: 12px; text-align: center; }

.main-graph-box { height:100%; }

.process-step  { width:100%; }
.process-step ul { display:flex; }
.process-step ul li { width:calc(100% / 6); text-align: center;  }
.process-step.step5 ul li { width:calc(100% / 5);  }
.process-step ul li .item { position:relative;  }
.process-step ul li .item:after { content:''; display:block; margin:0 auto; width:14px; height:14px; border-radius:100%; background-color: #9B9B9B; position: relative; z-index:2;}
.process-step ul li .item dl { height:42px;  font-size:11px; color:#9597A6;  margin-bottom:12px; }
.process-step ul li .item dl dd { font-size:14px; color:#9b9b9b; }
.process-step ul li .item:before { content:''; position:absolute; bottom:7px; right:-50%; left:50%; height:1px; background-color: #F0F1F7;}
.process-step ul li .item.current:after { background-color: var(--point-color1);}
.process-step ul li .item.current dl dd { font-size:16px; color:var(--point-color1); font-weight:700; line-height:1; white-space:nowrap;}
.process-step ul li:last-child .item:before { display:none; }
.process-step ul li .item.disabled dl,
.process-step ul li .item.disabled dl dd { color:#f0f1f7}
.process-step ul li .item.disabled:after { background-color: #F0F1F7; }

/* 프린트 우측 고정 영역 */
.quick-print { position: fixed; right:-320px; top:70px;  z-index:10; width:320px; }
.quick-print.active { right:0; border-radius:10px; box-shadow:0 5px 15px rgba(0,0,0,0.15); transition: all 0.5s ease; }
.quick-print.active .head .btn-flip { position: static; width:100%;}
.quick-print.active .head .btn-flip .after { display:flex;}
.quick-print.active .head .btn-flip .before { display:none;}

.quick-print .head .btn-flip {  position:absolute; left:-35px; top:0;  }
.quick-print .head .btn-flip .after {  height: 40px; padding:0 15px; display: none;  align-items: center; justify-content: space-between; background-color: #fff; border-radius:10px 0 0; border:1px solid var(--line-color1); border-bottom: 0; border-right:0;} 
.quick-print .head .btn-flip .after span { background:url(../img/common/ico_print_close.svg) no-repeat 50% 50%; width:23px; height:100%;  }

.quick-print .head .btn-flip .before { width:35px; padding:10px 0; text-align: center; border-radius:5px 0 0 5px; border:1px solid var(--line-color1); border-right:0; box-shadow:0 0 10px rgba(0,0,0,0.1);  background:#fff; transition: all 0.5s ease;}
.quick-print .head .btn-flip .before strong { color:var(--font-color2); }
.quick-print .head .btn-flip .before span { display:block; margin-top:5px; font-size:10px; color:var(--font-color4); }
.quick-print .head .btn-flip .before span:before {content:''; display:block; width:6px; height:6px; margin:0 auto; background: url(../img/common/ico_print_arr.png) no-repeat 0 0;}
.quick-print .head .btn-flip .before:hover { box-shadow:-5px 5px 10px rgba(0,0,0,0.3);}
.quick-print .body { width:320px; background-color: #fff; border:1px solid var(--line-color1); border-right:0; border-radius:0 0 0 10px; max-height:80vh; overflow: auto;}
.print-list {  } 
.print-list li { margin-bottom: 10px; ;}
.print-list li:last-child { margin-bottom: 0;}
.print-list li .item { display: flex; height:100px; border-radius:10px; border:1px solid var(--line-color2); box-shadow:3px 3px 7px rgba(0,0,0,0.05); overflow: hidden; }
.print-list li .item .name { width:60px; display:flex; justify-content: center; align-items: center; position: relative; font-size:13px; word-break: break-all; text-align: center;}
.print-list li .item .name small { position: absolute; left: 0; right:0; top:10px; text-align: center; font-size:11px; color:var(--font-color3); }
.print-list li .item .box { flex:1; background-color: var(--background-color3); position: relative;}
.print-list li .item .box .mv { height:100%;  font-size:0; }
.print-list li .item .box .btn-play { position: absolute; left: 50%; top:50%; color:#545871; font-size:55px; transform: translate(-50%, -60%);}
.print-list li .item .box .btn-alert { position: absolute; left: 50%; top:50%; width:42px; height:42px; border-radius:100%; background: rgba(255,83,83,0.3) url(../img/common/ico_alert.png) no-repeat 50% 50%; transform: translate(-50%, -60%);}
.print-list li .item .box .time { position: absolute; left:4px; right:4px; bottom:1px; display:flex; justify-content: space-between; }
.print-list li .item .box .time dl { text-align: center;}
.print-list li .item .box .time dl dt { font-size:11px; color:var(--font-color2); }
.print-list li .item .box .time dl dd { font-size:12px;  }
.print-list li .item .box .ready  {  text-align: center; position: absolute; left: 0; right:0; top:30px;}
.print-list li .item .box .ready strong { display: block; text-align: center; font-size:13px; font-weight: 600;}
.print-list li .item .box .ready .btn-download { font-size:12px; color:var(--font-color3);}
.print-list li .item .box .ready .btn-download i  {font-size:16px; vertical-align: middle; margin:-2px 0 0 4px;  }
.print-head { display:flex; height:40px; align-items:center; background:#f2f2f2; font-size:13px; text-align:center; }
.print-head p { width:25%; }
.print-list2 { padding-bottom:10px; }
.print-list2 li { display:flex; height:40px; align-items: center; font-size:13px; text-align: center;  border:1px solid transparent; transition: all 0.5s ease; }
.print-list2 li div { width:25%; word-break: break-all; line-height:1.2;}
.print-list2 li .btns {  display:flex; align-items: center; gap:3px; visibility: hidden; opacity:0; transition: all 0.5s ease; }
.print-list2 li .btns button { padding:0 5px; }
.print-list2 li .btns button i {  font-size:20px; color:var(--font-color4);transition: all 0.5s ease;  }
.print-list2 li .btns button:hover i { color:#000}
.print-list2 li:hover { border-color:var(--point-color1); }
.print-list2 li:hover .btns { opacity:1; visibility: visible; }
.print-list3 li  { padding:12px;  }
.print-list3 li .item { padding: 10px 4px 10px 10px; display:flex; align-items: center; gap: 10px;}
.print-list3 li .item.active { background:#F3F8FF;}
.print-list3 li .item .current { flex:0 0 28px; margin-right:15px; position:relative; text-align: center;}
/* .print-list3 li .item .current span { position:absolute; left:50%; transform: translateX(-50%); top:28px; width:43px; height:20px; text-align: center; line-height:20px; border-radius:20px; background-color: #EDF3FE; font-size:12px; color:#4C90F6; } */
.print-list3 li .item .current span { display:block; margin:2px auto 0; width:43px; height:20px; text-align: center; line-height:20px; border-radius:20px; background-color: #EDF3FE; font-size:12px; color:#4C90F6; }
.print-list3 li .item .auth { flex:1;}
.print-list3 li .item .auth .name { font-size:14px; font-weight:500; color:#000; word-break: break-all;}
.print-list3 li .item .auth .etc { font-size:12px;;  color:#909090;}
.print-list3 li .item .auth .etc span { margin-right:6px; }
.print-list3 li .item .times { display:flex; align-items:center; gap:12px; }
.print-list3 li .item .times .time { font-size:10px; line-height:16px; text-align: center;} 
.print-list3 li .item .times .btn-del { background:url(../img/common/ico_trash.svg) no-repeat 50% 50%; width:25px; height:25px;}
.print-list3 li .item.stop .current span { background-color: #FFEDED; color:#ff5353}
.print-list3 li .item.alert .current span { background-color: #F5F5F5; color:#7b7b7b}
.print-list3 li .item.alert .auth .name,
.print-list3 li .item.alert .auth .etc { color:#c9c9c9;}

.print-video {  height:400px; background:#000; border-radius:10px; overflow: hidden; position: relative;}
.btn-play { position:absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size:55px; color:#fff; }

.user-common { display:flex;  border:1px solid var(--line-color1); align-items: center; border-radius:10px; margin-bottom:15px;overflow:hidden; }
.user-common .place { background:var(--background-color4); padding-right:10px; padding:15px; margin-right:15px; }
.info-common { display:flex;gap:15px; }
.info-common dl { flex:1 1 auto; }
.info-common dl dt { color:var(--font-color3); margin-bottom:4px; }
.info-common dl dd { font-size:16px; font-weight:600; }


.description { text-align: center;  color:var(--font-color3); }
.description i { font-size:20px; vertical-align: middle; margin-top:-3px; }
.description2 {  color:var(--font-color3);padding-left:25px;  position: relative;}
.description2 i { font-size:20px; position: absolute; left: 0; top:0; }

.find-result { text-align: center; padding:60px 10px 90px; border-radius:10px; border:1px solid var(--line-color4); font-size:15px;  margin-bottom: 25px;;}
.find-result i { font-size:80px; color: var(--point-color1); }
.find-result p { margin-top:20px; color:var(--font-color3) }
.find-result strong { font-weight:700; color:#111;}


.loading { position:fixed; left: 0; top:0; padding:100px; background:rgba(0,0,0,0.8);}
.loading .ic { width:40px; height:40px; }
.loading .ic:before{ content:''; display:block; width:40px; height:40px; border-radius:100%; background-color: blue; animation: loadingCircle 2.5s linear infinite;}
.loading p { font-size:15px; color:#fff; }
.loading p:after { content:'.'; display:inline; animation: loadingTxt 2.5s linear infinite;}

/* .choice-wrap { display:flex; gap:60px; margin-bottom:20px;} */
.choice-wrap { display:flex; width: 1100px; gap: 20px; margin-bottom: 20px; }
.tip-wrap { margin-left:5px; position:relative; display: inline-block; vertical-align: middle; z-index:10;}
.tip-wrap i { font-size:25px; color:var(--font-color4); cursor: pointer;}
.tip-wrap .tip-box { position:absolute; left: -100px; top:40px; background:#f2f2f2; border-radius:5px; font-size:13px;  color:var(--font-color4); line-height:1.5; white-space: nowrap;  padding:10px; visibility: hidden; opacity:0; transition: all 0.5s ease; }
.tip-wrap .tip-box:before{  content:''; position:absolute; left:107px; top:-10px; width: 0;height: 0;border-style: solid;border-width: 0 5px 10px 5px;border-color: transparent transparent #f2f2f2 transparent;}
.tip-wrap:hover .tip-box { opacity:1; visibility: visible; }

.tip-wrap .tip-box strong { display:block; font-size:14px;  color:#000; }

@keyframes loadingCircle { 
	0% { background:green; }
	25% { background:yellow; }
	50% { background:red; }
	75% { background:palegreen; }
	100% { background:blue; }
}

@keyframes loadingTxt { 
	0% { content:'.'; }
	25% { content:'..'; }
	50% { content:'...'; }
	75% { content:'....';}
	100% { content:'.....'; }
}

.add-list { margin-top:15px; height:182px; overflow:auto; }
.add-list.auto { height:auto; }
.add-list ul li { margin-bottom:5px;  border-radius:5px; background-color: var(--background-color3); padding:10px 40px 10px 10px; position:relative;text-align:left; }
.add-list ul li .file { font-size:14px; display: block; word-break: break-all; }
.add-list ul li .btn { position:absolute; right:0; top:50%; transform: translateY(-50%); width:40px; height:40px;padding:0; transition: all 0.5s ease;  }
.add-list ul li .btn:hover { }







/**************************************************************************************************************
	변수
**************************************************************************************************************/
#wrap-admin {  padding:80px 30px 30px 290px; min-height:100vh;}
.header-admin { position:fixed; left:260px; top:0; right:0; height:50px; width:auto; background-color: #F8F8F8;  z-index:100; }
.header-admin .top { position:absolute; right:30px; top:0; height: 50px; display: flex; align-items: center; gap:15px;}
.header-admin .top .right { display:flex; align-items: center;}
.header-admin .top .admin-profile  { display: flex; align-items: center;}
.header-admin .top .admin-profile img { display:none; width:16px; height:16px; border-radius:100%;}
.header-admin .top .admin-profile:before { content:''; display:block; width:16px; height:16px; background:url(../img/admin/ico_top_user.png) no-repeat 0 0;}
.header-admin .top .admin-profile span { font-size:12px; color:#878787; font-weight:300; margin-left:5px; }
.header-admin .top .admin-profile span strong { font-weight:500; }
.header-admin .top .admin-name { color:var(--font-color4)}
.header-admin .top .btn-logout { font-size:12px; color:#878787; margin-left:30px; }

.aside-admin { position:fixed; left: 0; top:0; bottom:0; width:260px; z-index:100; background-color: #545871; }
.aside-admin h1 { height:100px; background: url(../img/admin/logo.png) no-repeat 50% 50%;}
.aside-admin .btn-dep1 { display:block;  line-height:60px; padding-left:64px; font-size:15px; color:#757eb6;font-weight:600;  background:#F4F6FD; position:relative;  transition: all 0.5s ease}
.aside-admin .btn-dep1:before{ content:''; position:absolute; left:35px; top:0; bottom:0; width:20px;background: url(../img/admin/ico_nav0.png) no-repeat 50% 50%; }
.aside-admin .btn-dep1.m0 { background-color:#545871; color:#8F92A8; }
.aside-admin .btn-dep1.m0:before { background-image: url(../img/admin/ico_nav0.png);}
.aside-admin .btn-dep1.m1:before { background-image: url(../img/admin/ico_nav1.png);}
.aside-admin .btn-dep1.m2:before { background-image: url(../img/admin/ico_nav2.png);}
.aside-admin .btn-dep1.m3:before { background-image: url(../img/admin/ico_nav3.png);}
.aside-admin .btn-dep1.m4:before { background-image: url(../img/admin/ico_nav4.png);}
.aside-admin .dep2 { background:#fff; }
.aside-admin .dep2,
.aside-admin .dep3 { display:none; }
.aside-admin .btn-dep2 { display:block; line-height:40px; position:relative; font-size:14px; color:#757eb6; padding-left:49px;  transition: all 0.5s ease }
.aside-admin .btn-dep2:before {content:''; position: absolute; left:36px; top:50%; width:4px; height:4px; border-radius:2px; background:#757eb6; }
.aside-admin .btn-dep3 { display:block; line-height:32px; position:relative; font-size:13px; color:#757eb6; padding-left:60px;  transition: all 0.5s ease;}
.aside-admin .btn-dep1:hover,
.aside-admin nav > ul > li.active .btn-dep1 { background:#DDE0EF;}
.aside-admin .btn-dep2:hover,
.aside-admin .dep2 > ul > li.active .btn-dep2 { background:#DDE0EF; font-weight:600; }
.aside-admin .btn-dep3:hover,
.aside-admin .dep3 > ul > li.active .btn-dep3 { background:#DDE0EF; font-weight:600; }

.title-head { padding: 0 25px; height:86px; display:flex; flex-direction: column; justify-content: center; border-radius:5px; background:#CBD7EB; margin-bottom:30px; }
.title-head h2 { color:#545871; font-size:13px; font-weight:400; margin-bottom:5px; }
.title-head h3 { color:#545871; font-size:20px; font-weight:700;  }

.title-head2 { padding: 20px 25px;   border-radius:5px; background:var(--background-color4); margin-bottom:30px; }
.title-head2 h2 { color:#000; font-size:30px; font-weight:700; text-align: center;}
.title-head2 h3 { color:#000; font-size:20px; font-weight:700;  }
.title-head2 .title { display:flex; justify-content: space-between; align-items: flex-end;  margin-bottom: 20px;; }
.title-head2 .table1 { background:#fff; margin-bottom:0; }

.board-head { display: flex; justify-content: space-between; margin-bottom:20px; }
.board-head .h3 { margin-bottom:0; }
.board-head .btns { display:flex; gap:8px; }

.btn-del { color:#8e8e8e; }
.langs { }
.langs ul { display:flex; align-items:center; margin-bottom:12px; } 
.langs ul li{ padding-right:10px; margin-right:11px; position:relative; }
.langs ul li:before{ content:''; position:absolute; right:0; top:2px; bottom:2px; width:1px; background-color: var(--line-color1);}
.langs ul li:last-child:before { display:none; }
.langs ul li button { font-size:14px;color:#CACACA;position:relative;  }
.langs ul li button:after{ content:''; position:absolute; left:0; right:0; bottom:-2px; height:2px; background:#000; opacity:0; transition:all 0.5s ease;}
.langs ul li button:before { content:''; display:inline-block; vertical-align: middle; background: url(../img/admin/ico_kor.png) no-repeat  0 0 ; width:20px; height:15px; margin:-3px 4px 0 0;}
.langs ul li button.kor:before { background-image: url(../img/admin/ico_kor.png) }
.langs ul li button.usa:before { background-image: url(../img/admin/ico_usa.png) }
.langs ul li button.ger:before { background-image: url(../img/admin/ico_ger.png) }
.langs ul li button.uk:before { background-image: url(../img/admin/ico_uk.png) }
.langs ul li button.jpn:before { background-image: url(../img/admin/ico_jpn.png) }
.langs ul li button.can:before { background-image: url(../img/admin/ico_can.png) }
.langs ul li.active button { color:var(--font-color1)}
.langs ul li.active button:after { opacity:1;}

.admin-search { border-radius:10px; border:1px solid var(--line-color4); margin-bottom:30px; overflow: hidden;}
.admin-search .title { height:50px; border-bottom:1px solid var(--line-color4);background-color:var(--background-color3);  }
.admin-search .title h4 { line-height:50px; padding-left:20px; font-size:16px; font-weight:600;  }
.admin-search .search-box { padding:20px;  }
.admin-search .search-box .item {display:flex; gap:15px; margin:0 -6px; padding-right:100px; height:45px; }
.admin-search .search-box .item dl { display:flex; align-items:center;  width:33.3333%; padding:0 6px;  }
.admin-search .search-box .item dl dt { font-size:14px; margin-right:20px; }
.admin-search .search-box .item dl dd { flex:1 1 auto; }
.admin-search .search-box .item dl dd .inp { width:100%; }
.admin-search .search-box .item + .item { margin-top:12px; }
.admin-search .btns { text-align:center ; }
.admin-search .btns button { min-width:100px; }

.order-search { border-radius:10px; border:1px solid var(--line-color4); margin-bottom:20px; overflow: visible; }
.order-search .search-box { padding:15px; display: flex; gap: 10px; justify-content: flex-start; }
.order-search .search-box + .search-box { padding-top: 0px; }
.order-search .search-box .left { width: 100%; min-width: 1000px; }
.order-search .search-box .right { width: 100%; }
.order-search .search-box .item { display:flex; gap:15px; height:auto; }
.order-search .search-box .item dl { display:flex; align-items:center; }
.order-search .search-box .item dl dt { font-size:14px; margin-right:20px; }
.order-search .search-box .item dl dd { flex:1 1 auto; }
.order-search .search-box .item dl dd + dd { margin-left: 10px; }
.order-search .search-box .item dl dd .inp { width:100%; }
.order-search .search-box .item .period { display: flex; }
.order-search .search-box .item .period > dl { height: 45px; }
.order-search .search-box .item .order-state {  }
.order-search .btns { text-align:center; float: right; }
.order-search .btns button { min-width:80px; }
.order-search .btn-box { display: flex; align-items: center; }
.order-search .btn-box .btn-orderadd { width:160px; height:55px; text-align:center; font-size:18px; color:var(--point-color1); border:1px solid var(--point-color1); border-radius:5px; font-weight:700;}
.order-search .btn-box .btn-orderadd i { font-size:22px; vertical-align: middle; margin:-4px 5px 0 0; }

.radio-item .radio { position: absolute; left: -9999px; }
.radio-item .radio:checked + span { background-color: #bcd7ff;}
.radio-item .radio:checked + span:before { box-shadow: inset 0 0 0 0.4375em var(--point-color1);}
.radio-item .radio:disabled + span { background-color: var(--background-color2)}
.radio-item .radio:disabled + span:before { background: var(--background-color2); border-color:var(--line-color2); }

.radio-item span { display: flex; align-items: center; padding: 0.375em 0.75em 0.375em 0.375em; border-radius: 99em; transition: 0.25s ease; }
.radio-item span:hover { background-color: #bcd7ff}
.radio-item span:before { display: flex; flex-shrink: 0; content: ""; background-color: #fff; width: 1.5em; height: 1.5em; border-radius: 50%; margin-right: 0.375em; transition: 0.25s ease; box-shadow: inset 0 0 0 0.125em var(--point-color1); }

.orderlist-filter-wrap { display: flex; justify-content: center; text-align: center; }
.filter-list { display: inline-block; }
.filter-list > button.on:after { content: ""; display: inline-block; position: absolute; top: -6px; right: 5px; width: 15px; height: 22px; background: url(../img/common/ic_order_select.svg) no-repeat center/contain; }
.filter-list.status-A > button.on,.filter-list.status-M > button.on { border-top: 2px solid #e99c00; }
.filter-list.status-D > button.on,.filter-list.status-F > button.on,.filter-list.status-W > button.on { border-top: 2px solid #de70a7; }
.filter-list.status-P > button.on,.filter-list.status-K > button.on,.filter-list.status-S > button.on { border-top: 2px solid #a25fbc; }
.filter-list.status-C > button.on { border-top: 2px solid #59647d; }
.filter-list.status-R > button.on { border-top: 2px solid red; }
.filter-list > button { position: relative; margin: 10px 5px 0; width: 90px; height: 108px; text-align: center; border: 1px solid #dedede; border-radius: 10px; }
.filter-list > button .text { margin: 0 2px; font-size: 14px; color: #9e9e9e; }
.filter-list > button .num { font-size: 20px; line-height: 1.2; color: #252525; }
.filter-list.status-R > button.stress { background-color: #FFF5F5;}
.filter-list.status-R > button.stress .text { color:var(--point-color2); }
.filter-list.status-R > button.stress .num { color:var(--point-color2); }

.icon-order { display: inline-block; margin-top: 6px; width: 40px; height: 40px; background: url() no-repeat; }
.icon-order.R { background-image: url(../img/common/ico_status_rescan.png); }
.icon-order.A { background-image: url(../img/common/ico_status_remake.png); }
.icon-order.M { background-image: url(../img/common/ico_status_memo.png); }
.icon-order.D { background-image: url(../img/common/ico_status_design.png); }
.icon-order.F { background-image: url(../img/common/ico_status_dc.png); }
.icon-order.W { background-image: url(../img/common/ico_status_wait.png); width: 30px;}
.icon-order.P { background-image: url(../img/common/ico_status_repair.png); }
.icon-order.K { background-image: url(../img/common/ico_status_work.png); }
.icon-order.S { background-image: url(../img/common/ico_status_shipping.png); }
.icon-order.C { background-image: url(../img/common/ico_status_complete.png); }

.filter-list.issue .filter .icon { display: inline-block; margin-top: 6px; width: 40px; height: 40px; background: url() no-repeat; }
.filter-list.issue .filter .icon.rescan{ background-image: url(../img/common/ico_issue_rescan.png); }
.filter-list.issue .filter .icon.print{ background-image: url(../img/common/ico_issue_print.png); }
.filter-list.issue .filter .icon.remake{ background-image: url(../img/common/ico_issue_remake.png); }
.filter-list.issue .filter .icon.repair{ background-image: url(../img/common/ico_issue_repair.png); }
.filter-list.issue .filter.on .icon.rescan { background-image: url(../img/common/ico_issue_rescan_after.png); }
.filter-list.issue .filter.on .icon.print { background-image: url(../img/common/ico_issue_print_after.png); }
.filter-list.issue .filter.on .icon.remake { background-image: url(../img/common/ico_issue_remake_after.png); }
.filter-list.issue .filter.on .icon.repair { background-image: url(../img/common/ico_issue_repair_after.png); }
.filter-list.issue .filter.on .text.rescan { color: #CF49E4; }
.filter-list.issue .filter.on .text.print { color: #FB8110; }
.filter-list.issue .filter.on .text.remake { color: #FF5353; }
.filter-list.issue .filter.on .text.repair { color: #66CC00; }

.order-list { width:100%; margin-bottom:15px;}
.order-list.cursor table tbody td { cursor: pointer; }
.order-list table { width:100%; border-collapse: collapse; } 
.order-list table thead th { padding:15px; border-top:1px solid var(--line-color4); border-bottom: 1px solid var(--line-color4); background-color: var(--background-color2); text-align: center; }
.order-list table tbody td { padding:4px; border-bottom: 1px solid var(--line-color4); color:var(--font-color3); text-align: center;transition: all 0.5s ease;}
.order-list table tbody tr {  transition: all 0.5s ease; }
.order-list table tbody tr:nth-child(odd) { background-color: #F9FAFD;} 
.order-list table tbody td .empty { text-indent: 20%;}
.order-list table tbody tr:hover,
.order-list table tbody tr.active  { background-color: var(--background-color4);}

.print-img { display:inline-block; vertical-align: middle; width:27px; height:27px; background: url() no-repeat; }
.print-img.wait { background-image: url(../img/common/ico_print_wait.png); }
.print-img.ing { background-image: url(../img/common/ico_print_ing.png); }
.print-img.complete { background-image: url(../img/common/ico_print_complete.png); }
.print-img.error { background-image: url(../img/common/ico_print_error.png); }

.list-tags > div { justify-content: center; }

.period-box { position: relative; display: flex; align-items: center;}
.period-box span { padding: 0 6px; }

.pagenate { text-align: center; padding-bottom: 10px; }
.pagenate div { display: inline-block; vertical-align: middle; }
.pagenate button { display: inline-block; vertical-align: middle; text-align: center; line-height: 34px; width:36px; height: 36px; font-size:14px;  color: #718096;  border-radius:100%; font-weight: 600; margin:0 2px;}
.pagenate button.active { font-weight: 600; color:#fff; background:var(--point-color1); }
.pagenate button.first,
.pagenate button.last{background: url(../img/admin/ico_page_first.svg) no-repeat 0 0; }
.pagenate button.prev,
.pagenate button.next{background: url(../img/admin/ico_page_prev.svg) no-repeat 0 0; }
.pagenate button.next { transform: rotate(180deg);}
.pagenate button.last { transform: rotate(180deg);}

.board-write { border-top:2px solid #000;  margin-bottom: 30px; padding-bottom: 20px;}
.board-write table { width:100%; border-collapse: collapse;}
.board-write table tbody th { font-size:16px; font-weight:500; border-bottom: 1px solid var(--line-color1); text-align: right; padding-right:20px; }
.board-write table tbody td { padding:8px 0; border-bottom: 1px solid var(--line-color1); }

.board-write.no-border { border:0; }
.board-write .h3 { margin-bottom:0; height:50px; border-bottom:1px solid var(--line-color4); background-color: var(--background-color3); font-size:16px; font-weight:600; line-height:50px; padding-left:20px; color:var(--font-color1); }
.board-write { border-top:0; border:1px solid var(--line-color4); border-radius:10px; overflow: hidden;}
.board-write table tbody th { font-size:14px; border-width:0; }
.board-write table tbody td { font-size:14px; border-width:0; padding-right: 29px;}
.board-write table tbody td .inp,
.board-write table tbody td .select { width:60%; }
.board-write table tbody td .flex {flex: 1; display: flex; flex-direction: row; justify-content: flex-start; margin-top: auto;}
.board-write .table1 thead th { border-width:1px;}
.board-write .table1 tbody td { border-width:1px;}

.board-read { margin:0 auto; width: 70%; border-top:0; border:1px solid var(--line-color4); border-radius:10px; overflow: hidden; }
.board-read .h3 { margin-bottom:0; height:50px; border-bottom:1px solid var(--line-color4); background-color: var(--background-color3); font-size:16px; font-weight:600; line-height:50px; padding-left:20px; color:var(--font-color1); text-align: center; }
.board-read table { width:100%; border-collapse: collapse; }
.board-read table tbody th { font-size:14px; border-width:0; }
.board-read table tbody td { font-size:14px; border-width:0; padding-right: 29px;}
.board-read .comment { border-top: 1px solid var(--line-color4); }
.board-read .comment ul { padding: 10px 0; }
.board-read .comment ul li { display: flex; align-items: center; justify-content: space-between; }
.board-read .comment ul li span { padding:0 80px 0 5px; line-height:30px; position:relative; text-align:left; }
.board-read .text { display: flex; align-items: center; gap: 20px; }
.board-read .button-box { display: flex; gap: 15px; justify-content: center; padding: 20px; }

.table1.text-left table thead th { text-align: left; padding-left:20px}
.table1.text-left table tbody td { text-align: left; padding-left:20px; padding-right:20px;}

.inquiry-head { display:flex; justify-content: center; position:relative; margin:20px 0 20px; ; }
.inquiry-head .tabs5 { display: inline-flex; padding:5px; border-radius:5px; border:1px solid var(--line-color1); }
.inquiry-head .tabs5 button { width:200px; height:44px; font-size:15px; color:var(--font-color4); border-radius:5px; transition: all 0.5s ease; }
.inquiry-head .tabs5 button:hover { background-color: var(--background-color3);}
.inquiry-head .tabs5 button.active { background-color: var(--point-color1); color:#fff;font-weight:700; }

.inquiry-head .btns { position:absolute; right:0; bottom:0; }
.inquiry-head > .flex{ position: absolute; left: 0; bottom:0; align-items: center;}

.inquiry-list ul { display:flex; border:1px solid var(--line-color4); border-radius:10px; box-shadow:5px 5px 20px rgba(0,0,0,0.1);  overflow:hidden; }
.inquiry-list ul li { flex:1; border-left:1px solid #eee }
.inquiry-list ul li.today { background:var(--background-color4) }
.inquiry-list ul li.holiday { background:var(--background-color2); }
.inquiry-list ul li.holiday .day,
.inquiry-list ul li.holiday .head h3 { color:var(--point-color2) }
.inquiry-list ul li .head { text-align: center; padding:15px 0; border-bottom: 1px solid var(--line-color4); margin-bottom: 20px;; }
.inquiry-list ul li .head h3 { font-size:17px; font-weight:700; line-height:25px;  }
.inquiry-list ul li .head .month { font-size:13px; color:var(--point-color1); margin:5px 0 4px ;}
.inquiry-list ul li .head .day  { font-size:13px; }
.inquiry-list ul li.today .head h3 { font-size:20px; color:var(--point-color1); }
.inquiry-list ul li .body { padding:0 10px; }

.inquiry-calendar-wrap { display:flex; gap:20px; }
.inquiry-calendar-wrap .calendar-wrap { flex:0 0 350px; }
.inquiry-calendar-wrap .calendar-wrap .h3 { }
.inquiry-calendar-wrap .list-box { flex:1;}
.inquiry-calendar-wrap .list-box .body ul { display:flex; flex-wrap:wrap; margin:0 -5px; gap:10px 0; }
.inquiry-calendar-wrap .list-box .body ul li { width:25%; padding:0 5px; }

.inquiry-box { border:1px solid var(--line-color4); border-radius:10px; overflow: hidden; margin-bottom: 30px;}
.inquiry-box.visible { overflow: visible; }
.inquiry-box.pd20 { padding: 20px;}
.inquiry-box div .btn-close { width:20px; height:20px; background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") no-repeat 50% 50%;margin:0; }

.table-summary table{ width:100%; border-collapse: collapse; font-size:15px;  }
.table-summary table thead th { background:var(--background-color3); height:50px; }
.table-summary table thead th.highlight { background-color:var(--background-color5); }
.table-summary table tbody td { padding:15px 0; text-align: center; font-size:15px; }
.table-summary table tbody td strong { font-size:24px; }
.table-summary table tbody td .kr { color:var(--font-color4); padding-top:5px; }
.table-summary .btn-more { width:100%; height:50px; border-top:1px solid var(--line-color4); font-size:15px; color:var(--font-color3);  background-color: #f5f5f5;  transition: all 0.5s ease;}
.table-summary .btn-more:after { display: inline;}
.table-summary .btn-more i { font-size:24px;  display: inline-block; vertical-align: middle; margin:-2px 3px 0 0; transition: all 0.5s ease;} 
.table-summary .btn-more:hover { background:var(--point-color1); color:#fff; }
.table-summary .btn-more:hover i { color:#fff }
.table-summary .btn-more.active i { transform: rotate(180deg); margin-top:-4px}
.table-summary .btn-more.active:after { }
.calcus-wrap {padding: 20px 20px; border:1px solid var(--line-color4); border-radius: 10px;}
.calcus { display:flex; justify-content: flex-end; align-items: center; gap:10px; padding:10px 20px 20px; }
.calcus div { margin-right:20px; width: 25%;}
.calcus span { margin-right:20px; font-size: 16px;}
.calcus input { margin-right:20px; font-size: 16px;}

.order-item { min-height:122px; border-radius:10px; border:1px solid var(--line-color4); padding:10px; box-shadow:3px 3px 7px rgba(0,0,0,0.05); position: relative; transition: all 0.5s ease; margin-bottom:10px; background-color: #fff; overflow:hidden }
.order-item:hover { border-color:var(--point-color1); box-shadow:3px 3px 15px rgba(0,0,0,0.2);}
.order-item:before { content:''; position:absolute; left:-1px; right:-1px; top:-1px; bottom:-1px; border-radius:10px; border:2px solid var(--point-color1); opacity:0; visibility: hidden; transition: all 0.5s ease;}
.order-item .title { display:flex; justify-content: space-between; font-size:12px;  margin-bottom:8px; } 
.order-item .title span { color:var(--font-color4); text-align:right; }
.order-item .user { display:flex; justify-content: space-between; align-items: center; margin-bottom:12px; }
.order-item .user .name { font-size:15px; font-weight:700; word-break: break-all;}
.order-item .user .type { font-size:12px;text-align: center; min-width: 60px;}
.order-item .user .type strong { font-size:14px; font-weight: 500;}
.order-item .user .date { text-align:center; min-width: 75px;}
.order-item .user .date strong { font-size:17px; }
.order-item .order { text-align: right; padding-top:7px; }
.order-item .order a { font-size:13px; color:var(--font-color3); }
.order-item .order a i{ vertical-align: middle; margin:-3px 0 0 3px; }
.order-item .before { cursor: pointer;}
.order-item .detail { display:none; margin:0 -10px -10px; padding:10px; }
.order-item .detail > div { border-top:1px solid var(--line-color4); }
.order-item .detail ul { display:block; border:0; border-radius:0; box-shadow:none; margin:0; height:auto; padding-top:8px; }
.order-item .detail ul li { width:auto; padding:0;  border:0; margin-bottom:12px; }  
/* .order-item .detail ul li .tooth-list-item { min-height:inherit; height:auto; font-size:12px; display:block; padding:0;  }
.order-item .detail ul li .tooth-list-item:before { display:none; }
.order-item .detail ul li .tooth-list-item h4 { font-size:12px; margin-bottom:4px; }
.order-item .detail ul li .tooth-list-item div { justify-content: flex-start;} */
.order-item .detail  .btn-detail { width:calc(100% + 20px); margin-left:-10px; margin-bottom:-10px; height:37px; background:#89B5F9; text-align: right; font-size:12px; font-weight:600; padding-right:10px;  color:#fff; transition: all 0.5s ease;}
.order-item .detail  .btn-detail:hover { background-color: var(--point-color1);}
.order-item .tags { margin-top:15px; }

.options ul { display:flex;  position:relative;}
.options ul li { width:150px; text-align: center ; margin-right:-1px;}
.options ul li .type { line-height:30px;  font-size:16px;   }
.options ul li .btn-flip { width:100%;  height:100px; border:1px solid var(--line-color1);transition: all 0.5s ease; position: relative;}
.options ul li.active .btn-flip,
.options ul li .btn-flip:hover { border-color:#000; position: relative; z-index:2;}
.options ul li .btn-flip:after { content:''; position: absolute; left: 50%; bottom:15px; margin-left:-2px; width:8px; height:8px; border-left:1px solid #000; border-bottom:1px solid #000; transform: rotate(-45deg); transition: all 0.5s ease;}
.options ul li .dep2 { position: absolute; left: 0; top:129px; width:calc(600px - 3px); border:1px solid var(--line-color1); display:none; }
.options ul li .dep2 div { height:50px; background: #fff; display: flex;  }
.options ul li .dep2 button { flex:1; font-size:15px; transition:all 0.5s ease; border:1px solid transparent; color:var(--font-color4) }
.options ul li .dep2 button:hover { border-color:var(--line-color3); color:#000; }

.options ul li.active .btn-flip:after { transform: rotate(135deg);}
.options ul li .h4 { text-align: left; color:#000;}
.options ul li .flex.gap10 { margin-bottom: 7px;}
.options label { display: block; width:80%; text-align: left;}

.options2-wrap { padding: 0 20px; width: 100%;}
.options2-wrap .optionBox { margin-bottom: 10px; display: flex;}
.options2-wrap .optionBox div ul { display: flex; position: relative;}
.options2-wrap .optionBox div ul li {display: inline-block; margin-right: 10px;}
.options2-wrap .optionBox div ul li button {padding: 0 15px; height: 45px; border: 1px solid var(--line-color1); border-radius: 5px; color: var(--font-color3); font-size: 14px; background-color: var(--background-color1);}
.options2-wrap .optionBox div ul li button.active { font-weight: 700; background-color: var(--point-color1); color: #fff;}
.options2-wrap .selectBox .row { margin: 10px; }
/* .options2-wrap .selectBox .row { margin: 10px 0; } */
.options2-wrap .selectBox .row ul {display: flex; max-height: 35px;}
.options2-wrap .selectBox .row ul li { display: inline-block; }
.options2-wrap .selectBox .row ul li.title { width: 100px; vertical-align: middle; }
/* .options2-wrap .selectBox .row ul li.title { width: 140px; vertical-align: middle; text-align: right; margin-right: 20px;} */
.options2-wrap .selectBox .row ul li button {line-height: 35px; width: 100px; border: 1px solid var(--line-color1); border-radius: 5px; color: var(--font-color3); font-size: 14px;}
.options2-wrap .selectBox .row ul li button.active { font-weight: 700; transition:all 0.5s; background-color: var(--point-color1); color: #fff;}

.lost-wrap { font-size:14px; }
.lost-wrap .t1 { font-weight:600; margin-bottom:8px; position:relative; padding-left:19px;}
.lost-wrap .t1 i { position:absolute; left:0; top:3px;}
.lost-wrap .t2 {  margin-bottom:30px; color:var(--font-color4); }
.lost-wrap .t2 span { color:var(--point-color1); font-weight:600;}
.lost-wrap .t3 {  margin-bottom:25px; color:var(--point-color2); padding-top:25px; border-top:1px solid var(--line-color4); }
.lost-wrap .btn-lost { font-size:16px; font-weight:600; color:var(--font-color1); text-decoration: underline !important; margin-top:8px;}

.btn i { transition: all 0.5s ease; }
.btn.active .xi-angle-down { transform: rotate(180deg); }

.gear-edit { padding:10px; background:#f2f2f2; border-radius:20px; display:flex; flex-wrap:wrap; gap:10px;}
.gear-edit .item { height:28px; line-height:28px; border-radius:24px; padding:0 3px 0 10px; font-size:13px; display:flex; align-items:center; gap:20px; background-color: var(--point-color1); color:#fff;  }
.gear-edit .item .btn-del { width:22px; height:22px; background:#fff url(../img/common/ico_del2.svg) no-repeat 50% 50%; border-radius:100%;}
.gear-edit .btn { height: 28px; border-radius:24px; background-color: #fff; border:1px solid var(--line-color3); font-size:14px; }

/* React Calender CSS OVERRIDE */
.calendar-dropdown-container input:hover { cursor: pointer; }
.calendar-menu { position: absolute; width: 100%;}
.react-calendar { position: absolute; z-index: 11; top: 2px; left: 0; border-radius: 3px; border: 1px solid var(--line-color1); }
.react-calendar__navigation__label { background: #dbe9f7 !important; }
.inner1100 .react-calendar { width: 380px; top: 2px;}
.period-box .react-calendar { width: 280px; }
.modal-body .react-calendar { width: 100%; }
.holiday abbr { color: #d10000;}

.invoice-wrap { width:800px; margin:0 auto; font-family: 'Malgun Gothic', '맑은 고딕', '돋움', 'Roboto', 'Noto Sans JP', 'MS PGothic', 'Meiryo', 'Osaka', sans-serif; }
.invoice-wrap .logo { padding:15px 0; border-bottom:1px solid #d9d9d9; }
.invoice-wrap .title { font-size:26px; color:#000; padding: 10px 0 30px;}
.invoice-wrap .subTitle { font-size:18px; font-weight:600; padding-bottom:14px; color:#555;border-bottom:3px solid #4C90F6}
.invoice-wrap .invoice-table { width: 100%; margin-bottom: 20px; border-bottom: 1px solid #ddd; }
.invoice-wrap .invoice-table thead th{ padding: 12px; font-size:16px; font-weight:600;color:#555; text-align: center;}
.invoice-wrap .invoice-table tbody td{ padding: 12px; font-size:18px; font-weight:500; color:#000; text-align: center;}
.invoice-wrap .invoice-table tbody strong{ font-size:24px; font-weight:700; color:#4c90f6;}
.invoice-wrap ul { line-height: 30px; font-size:12px; color:#000;}

/* KR : { font-family:'Malgun Gothic','맑은 고딕',dotum,'돋움',sans-serif; } */
.invoice-preview { width:100%; margin:0 auto; font-family: 'Malgun Gothic','맑은 고딕', '돋움' ,'Roboto', 'Noto Sans JP', 'MS PGothic', 'Meiryo', 'Osaka', sans-serif;}
.invoice-preview .invoice-table { width: 100%; margin-bottom: 20px; border-bottom: 1px solid #ddd; }
.invoice-preview .invoice-table thead .title { font-size:30px; color:#000; padding: 10px 0 10px; }
.invoice-preview .invoice-table thead .month { font-size:14px; font-weight:600; color:#555; text-align:right; }
.invoice-preview .invoice-table tbody th { font-size:15px; font-weight:800; background-color: var(--background-color3); border: 1px solid #929292; padding: 4px;}
.invoice-preview .invoice-table tbody td { font-size:14px; font-weight:500; border: 1px solid #929292; padding: 4px; color:#000; text-align: center; }
.invoice-preview .invoice-table strong{ font-size:24px; font-weight:700; color:#4c90f6;}
.invoice-preview ul { line-height: 30px; font-size:12px; color:#000;}

.payment-wrap { display: flex; margin-bottom: 25px; border: 1px solid var(--line-color1);}
.payment-wrap div { flex: 1; border: 1px solid #fff; }
.payment-wrap div table { width: 100%; border: 1px solid var(--line-color1);}
.payment-wrap div table tr { padding: 10px; border-bottom:1px sold var(--line-color1); border-top:1px solid var(--line-color1);}
.payment-wrap div table th { padding: 10px; border-right:1px sold var(--line-color1); border-left:1px solid var(--line-color1);}
.payment-wrap div table td { padding: 10px; border-right:1px sold var(--line-color1); border-left:1px solid var(--line-color1);}

.transaction-header {display: flex; padding:10px 0px; justify-content: space-between;}
.transaction-header .item {flex: 1; text-align: center;}
.transaction-header .btn-ico {  font-size:30px; color:var(--font-color3); transition: all 0.5s ease;}
.transaction-header .btn-ico:hover { transform: translateY(3px); color:var(--point-color1);}
.transaction-content {padding: 20px; border: 1px solid var(--line-color1); border-radius: 10px;}
.transaction-content .item {border: 1px solid var(--line-color1); padding: 1px; margin-bottom:25px;}

.table3 { padding-top: 10px; border: 1px solid #d1d1d1;}
.table3 .title {font-size: 18px; font-weight: 700; color: var(--font-color1); margin-bottom: 15px; text-align: center;}
.table3 table { width:100%; border-collapse: collapse;}
.table3 table thead th { padding:10px 15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); border-right: 1px solid var(--line-color1); background-color: var(--background-color3); }
.table3 table thead th:last-child { border-right: 0px;}
.table3 table tbody td { padding:10px 15px; border-top:1px solid var(--line-color1); border-bottom: 1px solid var(--line-color1); border-right: 1px solid var(--line-color1); color:var(--font-color3); text-align: center;}
.table3 table tbody td:last-child { border-right: 0px;}
.table3 table tbody tr:last-child { background-color: var(--background-color3); border-bottom: 0px;}
.table3.hover-type table tbody tr { cursor: pointer;}
.table3.hover-type table tbody tr:hover,
.table3 table tbody tr.active { background-color: var(--background-color4);}
.table3.hover-type table tbody tr:hover td,
.nohover-type table tbody tr:hover{ pointer-events: none;}
.table3 table tbody tr.active td { color:#111;}
.table3 table tbody td.chk { background-color: #F6F7FC; font-size:16px; color:#4ECB71;}
.table3 tr.disabled { opacity:0.5; }
.table3 table .add-textarea {text-align: center; resize: none; word-break: break-word; width: 100%; border: 0px; outline: none; background-color:var(--background-color6); color: var(--font-color3);}
.table3 table .adjust {background-color: var(--background-color5);}
.table3 table .add {background-color: var(--background-color2);}
.table3 table .add:hover {background-color: var(--background-color6); cursor: pointer; font-size: 14px; font-weight: 800; transition: all 0.3s ease;}

.snapshot.container { display: flex; justify-content: space-between; flex-wrap: wrap; gap: 20px;}
.snapshot .item { width:48%; height: 260px; }
.snapshot .item .title{ height: 20px; }
.snapshot .item > img{ width: 400px; height:240px;}

/* 주소 찾기 */
.pac-container { z-index: 1055 !important; } 
.pac-item { font-size: 16px !important; padding: 10px !important; height: 50px !important; } 
.tip-container { height: 250px; padding: 12px; } 
.tip-container p { margin: 2px 0; } 
.tip-title {font-size: 14px;padding-bottom: 8px; }
.tip-highlight { color: var(--point-color1); font-weight: bold; }

@media all and (max-width:1600px) {
	/* .home-main > .left .row { height:35%; }
	.month-amount { padding:10px; }
	.month-amount .totals .h3 { margin-bottom:2px; }
	.month-amount .totals .price {  margin-bottom:4px; font-size:16px; }
	.month-amount .totals .price strong { font-size:27px; line-height:1;}
	.month-amount .totals .before { font-size:12px; }
	.month-amount .goal .val { height:50px;}
	.month-amount .goal .val div { height:42px; } */
	.btn-refresh2 { font-size:10px; }
	.order-list2 { padding:10px; }
	.order-data .col-box > div:nth-child(2) .section dl { width:110px; padding-right:10px; margin-right:10px; }
	.order-data .col-box > div:nth-child(2) .section dl dd { word-break: break-all;}
	.order-data .col-box > div:nth-child(2) .section + .section dl { width:auto; }
}